import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Container } from '@mui/material'
import { Typography } from '@mui/material'
import { Card } from '@mui/material'
import { Box} from '@mui/system'
import { Stack } from '@mui/material'
import { Divider } from '@mui/material'
import {TextField} from "@mui/material"
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getProjets, getProjetsSuperUser } from '../../toolkit/projet';
import StyledMenu from '../Cards/createCard/styledMenu';
import { useCreateMenuStyles } from '../Cards/createCard/styledMenu';


export default function ContainerCreateMenu({ 
    isOpen, 
    isUpdate, 
    handleClose,
    project
}) {
  const classe=useCreateMenuStyles();
  const [fondImage,setFondImage]=useState(project ? project.backgroundImage : "/images/bg1.jpeg");
  const {url} = useSelector(state => state.user);
  const [erreur, setErreur] = useState(false);
  const fond=[{id:1,url:'/images/bg1.jpeg'},{id:2,url:'/images/bg2.jpeg'} ,{id:3,url:'/images/bg3.jpg'},{id:4,url:'/images/bg4.jpeg'}]
  const dispatch = useDispatch();
  const [errorName, setErrorName] = useState(false)
  const [errorDescription, setErrorDescription] = useState(false)
  const [isLoading,setIsLoading] = useState(false)

  
  const initialState = {
      name: project ? project.name : '',
      description: project ? project.description : ''
  }
  const [projectReference, setProjectReference] =  useState(initialState)

  const Choicefond=(id)=>{
    const choice=fond.filter((choix)=>choix.id==id);
    setFondImage(choice[0].url);
  }
   
  const handleCloseAll = () =>{
    if(!isUpdate){
      setProjectReference({
        name:'',
        description:''
      })
    }
    handleClose()
  }
  const handleChange = (e) =>{
      e.preventDefault()
    setProjectReference({
        ...projectReference,
        [e.target.name]: e.target.value,
    })
    if(e.target.name === 'name'){
        setErrorName(false)
    }
    if(e.target.name === 'description'){
        setErrorDescription(false)
    }
  }

  const handleSubmit = () => {
    let isValid = true
    if(!projectReference.name){
        setErrorName(true)
        isValid =  false
    }
    if(!projectReference.description){
        setErrorDescription(true)
        isValid = false
    }
    if(isValid){
        setIsLoading(true)
        if(isUpdate){
            axios.patch(url + `projet/${project.id}/`, {
                "name": projectReference.name,
                "description": projectReference.description,
                "userIdId": localStorage.getItem('user-this_id'),
                "backgroundImage" : fondImage,
              })
              .then(res => {
                  if(res && res.data){
                    dispatch(getProjets())
                    dispatch(getProjetsSuperUser(localStorage.getItem('user-this_id')));
                    handleCloseAll()
                    setIsLoading(false)
                  }
              }).catch((err)=>{
                setErreur("Merci de bien vouloir vérifier les champs");
                setIsLoading(false)

              })
          }else{
            axios.post(url + `projet`, {
              "name": projectReference.name,
              "description": projectReference.description,
              "userIdId": localStorage.getItem('user-this_id'),
              "backgroundImage" : fondImage,
              "createDate": new Date(),
            })
            .then(res => {
                if(res && res.data){
                    dispatch(getProjets());
                    dispatch(getProjetsSuperUser(localStorage.getItem('user-this_id')));
                    handleCloseAll();
                    setIsLoading(false);
                }
            }).catch((err)=>{
              setErreur("Merci de bien vouloir vérifier les champs");
              setIsLoading(false)
            })
          }
    }
  }
  return (
    <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        open={isOpen}
        onClose={handleCloseAll}
      >
      <Card  className={classe.root}  sx={{ minWidth: 275,maxWidth:275 }}>
        <Stack>
        <Container sx={{textAlign:'center'}}>
          <Typography >
          {isUpdate ? 'Modifier un projet' : 'Créer un projet'}
          </Typography>
          </Container>
        </Stack>
        <Divider/>
        <Box className={classe.Container}
        >
            <Stack direction="row">
              <Stack>
               <Typography component='h4' sx={{marginTop:'2%',fontWeight:'bold'}}>
                  Titre de projet 
                <TextField
                  fullWidth
                  name="name"
                  label="Le titre de votre projet"
                  type="text"
                  size="small"
                  error={errorName}
                  value={projectReference.name}
                  onChange={(e) => handleChange(e)}
                  sx={{marginTop:'2%'}}
                  helperText={
                    errorName && <Typography>Votre titre est obligatoire</Typography> 
                  }
                />
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row">
              <Stack>
               <Typography component='h4' sx={{marginTop:'2%',fontWeight:'bold'}}>
                  Description 
                <TextField
                  sx={{width:'100%',marginTop:'2%'}}
                  label="Description de votre projet"
                  multiline
                  value={projectReference.description}
                  error={errorDescription}
                  rows={4}
                  onChange={(e) => handleChange(e)}
                  placeholder="Ajouter une description"
                  variant="filled"
                  name='description'
                  helperText={
                      errorDescription && <Typography>Votre description est obligatoire</Typography> 
                  }
                />
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{marginTop:'2%'}}>
            <Typography sx={{fontWeight:700}}>Fond d'écran</Typography>
            <Box>
              <img src={fondImage} className={classe.imagefond}  alt='...'></img>
            </Box>
            <Divider/>
            <Typography className={classe.err} variant='h8'>
            {!!erreur && erreur}
            </Typography>
            <Stack direction='row' spacing={4}>
              <Avatar onClick={()=>Choicefond(1)} src='/images/bg1.jpeg' variant='square' className={classe.choice}/>
              <Avatar  onClick={()=>Choicefond(2)} src='/images/bg2.jpeg' variant='square' className={classe.choice}/>
              <Avatar onClick={()=>Choicefond(3)} src='/images/bg3.jpg' variant='square' className={classe.choice}/>
              <Avatar onClick={()=>Choicefond(4)} src='/images/bg4.jpeg' variant='square' className={classe.choice}/>
            </Stack>
              <Stack sx={{marginTop:'8%'}}>
                <Button 
                variant='contained'
                size="large"
                onClick={() => handleSubmit()}
                >
                  {isLoading ? 'Chargement...' : (isUpdate ? 'Modifier' : 'Créer')}
                </Button>
              </Stack>
          </Stack>
        </Box>
      </Card>
    </StyledMenu>
  );
}