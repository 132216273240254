import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import { initialState } from '../../toolkit/user';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Modal,
} from '@mui/material'
import SignUp from './ajout';

const columns = [
  { field: 'firstName', headerName: 'First name', width: 230 },
  { field: 'lastName', headerName: 'Last name', width: 230 },
  { field: 'email', headerName: 'Mail', width: 330 },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Personnel() {
  const [userAdmin, setUserAdmin] = useState(null)
  const [userSimple, setUserSimple] = useState(null)
  const [checkedSwitch, setCheckedSwitch] = useState(false);
  const handleChange = (event) => {
    setCheckedSwitch(event.target.checked);
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addUser, setAddUser] = useState(null)
  useEffect(() => {
    async function fetchData() {
      const userAdmin = await axios.get(initialState.url +"user/list/true");
      setUserAdmin(userAdmin.data);
      const userSimple = await axios.get(initialState.url +"user/list/false");
      setUserSimple(userSimple.data);
    }
    fetchData();
    
  }, [addUser]);
  return (
    <div style={{ 
      width: '98vw',
      height: '55vh',
      spacing: 0,
      justify: 'space-around',
      margin: 10,
      marginBottom: 90
      }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <SignUp setOpen={setOpen} setAddUser={setAddUser} addUser={addUser}/>
          </Box>
        </Modal>
        {
          userAdmin && userSimple ?
          <>
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <FormControlLabel 
                label='Staff' 
                control={<Switch checked={checkedSwitch} onChange={handleChange} 
                color='success'/>} 
              />
              <Button onClick={handleOpen}>Ajouter</Button>
            </Box>
            <DataGrid
              rows={checkedSwitch ? userAdmin : userSimple}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              style={{ 
                margin: '2%'
                }}
            />
          </> 
          :
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress />
          </Box>
        }
      
    </div>
  );
}