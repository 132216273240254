import { createSlice } from "@reduxjs/toolkit";


const initialState = {
     open:false
}

const ModalSlice = createSlice({
     name:'modal',
     initialState,
     reducers:{
          setOpen :(draft,action)=>{
               draft.open = true;
          },
          setClose:(draft,action)=>{
               draft.open = false;
          }

     }

})

export const {setOpen,setClose} = ModalSlice.actions
export default ModalSlice;
