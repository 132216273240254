import { Fragment,useState } from "react"
import {Modal,Typography,Grid,Box} from "@mui/material"
import {Divider} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import LabelIcon from '@mui/icons-material/Label';
import {styled} from "@mui/styles"
import LabelCard from "./labelCard";

import StyledButton from "./StyledButton";

const Style = {
     fontSize:'12px',
     position: 'absolute',
     top: '50%',
     left: '50%',
     maxWidth:300,
     minWidth:300,
     transform: 'translate(-50%, -50%)',
     bgcolor: 'background.paper',
     border: '2px solid #fff',
     boxShadow: 24,
     p: 2,
};

const Styleddivone=styled('div')({
     alignItems:'center',
     display:'flex'
})

const StyleddivTwo=styled('div')({
     align:'left'
})

export const StyledTitle =styled('p')({
     textAlign:'center',
     fontSize:'12px',
     color:'#5e6c84',
     lineHeight:'20px'

})

const StyledInput = styled('input')({
     outline:'none',
     border:'2px solid #aaa',
     boxSizing:'border-box',
     color:'#17b4d',
     fontSize:'12px',
     lineHeight:'20px',
     fontWeight:'400',
     borderRadius:'3px',
     padding:'8px 12px',
     boxShadow:'#dfe1e6',
     backgroundColor:'#fbfcfd',
     width:'100%',
     height:'32px'

})


const LabelModal = () =>{
     const BG_COLOR = ['#7BC86C','#F5EA92','#FFAF3F','#EF7564',"#CD8DE5","#5BA4CF"]

     const [openLabel,setOpenLabel]=useState(false);
     
     const handleCloseLabel= () => setOpenLabel(false);
     const handleOpenLabel = () => setOpenLabel(true);
    
     return(
          <Fragment>
               <StyledButton
                         variant='contained'
                         onClick={handleOpenLabel}
                    >
                         <Styleddivone>
                              <StyleddivTwo>
                                   <LabelIcon/>
                              </StyleddivTwo>
                              Etiquetes
                         </Styleddivone>
               </StyledButton>
               <Modal
                    open={openLabel}
                    onClose={handleCloseLabel}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
               >
                    <Box sx={Style}>
                         <Grid container mt={2}>
                              <Grid item xs={11}>
                                   <Divider/>
                                        <StyledTitle>Etiquetes</StyledTitle>
                                   <Divider/>
                              </Grid>                    
                              <Grid item xs={1}>
                                   <CloseIcon  style={{ 
                                        marginLeft:'20%',
                                        cursor:'pointer',
                                        background:'red',
                                        padding:'5px',
                                        color:'white',
                                        borderRadius:'50%'
                                   }} sx={{fontSize:'large'}} onClick={handleCloseLabel}/>     
                              </Grid>
                         </Grid>
                         <Grid container>
                              <Grid item xs={12} lg={12} md={12}>
                                   <StyledInput placeholder="Parcourir les étiquettes..." type="text"/>
                              </Grid>
                         </Grid>
                         <Grid container>
                              {BG_COLOR.map((bgcol,index)=>(
                                   <LabelCard key={index} bgcolor={bgcol}/>
                              ))}
                         </Grid>
                    </Box>
               </Modal>                  
          </Fragment>
     )
}

export default LabelModal