import React from "react"
import {Modal,Box } from "@mui/material"
import { useDispatch } from "react-redux"
import {saveChangeModalKanban,reinitialiseChecklist} from "../../toolkit/projet"
import CardMenu from "../Cards/kanban/cardMenu"
import {styled} from "@mui/styles"

const CarteModal = ({colonne, open, setOpen}) =>{
  const dispatch = useDispatch()
  const handleClose = () =>{
    dispatch(reinitialiseChecklist());
    setOpen(false)
  }
  
  const saveChange = async (confirmation = "Validé") => {
    await dispatch(saveChangeModalKanban(confirmation));
    setOpen(false);
  }
  return(
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          overflowY:'scroll',
          paddingTop:'0px'
      }}
    >
      <MystyledBox>
          <CardMenu save={colonne === 3 ? 'Terminé' : 'Validé'} handleClose={handleClose} saveChange={saveChange}/>
      </MystyledBox>
    </Modal>
  )
}

const MystyledBox  = styled(Box)({
  width:'40%',
  overflowY:'scroll',
  background:'#fff',
  borderRadius:5,
  border:'none',
  padding:'2%'

})

export default CarteModal;