import Navbar from "../Navbars/Navbar"


export default function TabHeader(){
     return(
          <>
          <header>
               <title>TICKETING</title>
              { /* Pour que le rendu et le zoom tactile soient corrects pour tous les périphériques, ajoutez la balise meta viewport à votre élément */}
               <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
          </header>
          <main>
               <Navbar/>
          </main>
          </>
     )
}