import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContainerCreateMenu from './ContainerCreateMenu'
import {Color} from "../palette/color"


export default function CreateMenu() {

  const [isOpen, setIsOpen] =  useState(false)

  const handleClose = () =>{
    setIsOpen(false)
  }

  const handleOpen = () =>{
    setIsOpen(true)
  }
  const handleClick = (event) => {
    setIsOpen(true)
  };
  
 
  return (
    <div style={{fontSize:12}}>
      <Button
        id="demo-customized-button"
        aria-controls={isOpen ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{fontSize:'12px',
        textAlign:'center',
        fontWeight:700,
        marginLeft:'20%',
        color:'#fff',backgroundColor:Color.primary,
        textTransform: "capitalize"}}
      >
        Créer
      </Button>
      <ContainerCreateMenu isOpen={isOpen} isUpdate={false} handleClose={handleClose} handleOpen={handleOpen}/>
    </div>
  );
}
