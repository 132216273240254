import TabHeader from '../../components/Headers/tableauHead'
import Footer from '../../components/Footers/Footer'


export default function Tablayout({ children, home }) {
  return (
      <>
        <TabHeader/>
          <main style={{ overflowX:'hidden'}}>
            {children}
          </main>
        <Footer/>
      </>
  )
}