import React from "react"
import Section1 from "../../components/Accueil/Section1"



const Accueil = ()=>{
    return(
        <>
            <Section1/>
        </>
    )
}
export default Accueil;