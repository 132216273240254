import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './router/AppRouter';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux"
import store from "./toolkit/store"

ReactDOM.render(
     <Provider store={store}>
          <AppRouter />
     </Provider>,
document.getElementById('root'));

reportWebVitals();