import React,{Fragment} from "react"
import {Grid,Stack} from "@mui/material"



import MembreModal from "./Membre"
import ChecklistModal from "./Checklist"
import DateModal from "./Date"
import LabelModal from "./label"




const Section2 = () =>{
     
     
     return(
          <Fragment>
               <Stack spacing={1} mt={4} ml={3}>
                    <Grid container spacing={1} pt={1}>
                         <Grid item xs={12} md={6}  lg={3}>
                              <MembreModal/>
                         </Grid>
                         <Grid item  xs={12} md={6}  lg={3}>
                              <ChecklistModal/>
                         </Grid>
                         <Grid item  xs={12} md={6}  lg={3}>
                              <DateModal/>
                         </Grid>
                         <Grid item  xs={12} md={6}  lg={3}>
                              <LabelModal/>
                         </Grid>
                    </Grid>
               </Stack>
          </Fragment>
     )
}

export default Section2;