import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./user";
import axios from "axios";
import produce from "immer";

export const getNotifications = createAsyncThunk(
    "projets/getNotifications",
    async(id_user, getState) => {
        const resultat = await axios.get(initialState.url +"label/notificationAdmin/"+id_user);
        return resultat.data;
    }
)

export const getProjets = createAsyncThunk(
    "projets/getProjets",
    async(dispatch, getState) => {
        const resultat = await axios.get(initialState.url +"projet");
        return resultat.data;
    }
)

export const saveChangeModalKanban = createAsyncThunk(
    "projets/saveChangeModalKanban",
    async(data,  { getState }) => {
        const state = getState();
        if(data === "Validé") {
            if(state.projet.membreChecklist && state.projet.membreChecklist.length){
                var membre = [];
                state.projet.membreChecklist.map((element) => membre.push(element.id));
                await axios.post(initialState.url + "label/membre", {
                    "label_id": state.projet.id_label,
                    membre
                })
            }
            
            if(state.projet.checklist && state.projet.checklist.length) {
                await axios.post(initialState.url + "checklist/array", state.projet.checklist);
            } 
            
            const newDateDeadline = state.projet.deadline ? new Date(state.projet.deadline) : ""
            await axios.put(initialState.url +"label/"+state.projet.id_label , {    
                "description": state.projet.descriptionLabel,
                "deadline": newDateDeadline,
            });
        }

        await axios.get(initialState.url + "label/verifyColonneId/" + state.projet.id_label+"/"+data)
        return state.projet.deadline;
    }
)

export const getProjet = createAsyncThunk(
    "projets/getProjet",
    async(id, getState) => {
        const resultat = await axios.get(initialState.url +"projet/"+id);
        return resultat.data;
    }
)

export const getProjetsSuperUser = createAsyncThunk(
    "projets/getProjetsSuperUser",
    async(id, getState) => {
        return await fetch(initialState.url +"projet/user/"+id, {method:'GET', headers: {'Authorization': localStorage.getItem('user-token')}} ).then(
            (res) => res.json()
        )
    }
)

export const getLabels = createAsyncThunk(
    "projets/getLabels",
    async(id, getState) => {
        const res = await axios.get(initialState.url +"label/projet/"+id);
        return res.data;
    }
)

export const createTicket = createAsyncThunk(
    "projets/createTicket",
    async(data, getState) => {
        return await axios.post(initialState.url +"label/", {
            "name": data.draftCard.title,"etat": 0,"project_id": data.id_projet, "colonne_id": data.id_colonne, description: data.draftCard.description, "user_id": localStorage.getItem('user-this_id')
        })
        .then(res => {
            return res
        }).catch((err)=>{
        console.log(err)
        })
    }
)


export const modifyColumnTicket = createAsyncThunk(
    "projets/modifyColumnTicket",
    async(data, getState) => {
        return await axios.put(initialState.url +"label/"+data.id_ticket+"/", {
            "colonne_id": data.id_colonne,
            "name": data.titre
        })
        .then(
            res => res
        ).catch((err)=>{
            console.log(err)
        })
    }
)

export const createChecklist = createAsyncThunk(
    "projets/createChecklist",
    async(data, getState) => {
        return await axios.post(initialState.url +"checklist", {
            createDate: new Date(),
            deadline: new Date(),
            description: "desc",
            isChecked: 0,
            labelIdId: data.label_id,
            name: data.checklist,
            objectif: "Objectifs",
            userIdId: localStorage.getItem('user-this_id'),
        })
        .then(res =>  res.data
        ).catch((err)=>{
            console.log(err)
        })
    }
)

export const deleteChecklist = createAsyncThunk(
    "projets/deleteChecklist",
    async(data, getState) => {
        return await axios.delete(initialState.url +`checklist/${data.id}`)
        .then(res =>  res.data
        ).catch((err)=>{
            console.log(err)
        })
    }
)

export const updateChecklist = createAsyncThunk(
    "projets/updateChecklist",
    async(data, getState) => {
        return await axios.post(initialState.url +"checklist", {
            createDate: new Date(),
            deadline: new Date(),
            description: "desc",
            isChecked: 0,
            labelIdId: data.label_id,
            name: data.checklist,
            objectif: "Objectifs",
            userIdId: localStorage.getItem('user-this_id'),
        })
        .then(res =>  res.data
        ).catch((err)=>{
            console.log(err)
        })
    }
)

export const projetSlice = createSlice({
    name: "projet",
    initialState: {
        personnel: false,
        projet: [],
        status: null,
        id_projet: null,
        projetUser: [],
        id_ticket: null,
        label:[],
        checklist: [],
        detailsProjet: null,
        membreChecklist: [],
        descriptionLabel: null,
        nameLabel: null,
        equipeProjet: [],
        id_label: null,
        afficheDeadline: null,
        deadline: null,
        labels: [],
        nbrNotification: 0,
        notifications : []
    },
    reducers: {
        incrementNotification: (state, action) => {
            let {nbrNotification, notifications, notificaion } = action.payload;
            state.notifications = produce(notifications, (draft) => {
                draft.unshift(notificaion);
            })
            state.nbrNotification = nbrNotification + 1;
        },

        definirProjet: (state, action) => {
            state.id_projet = action.payload
        },

        changeStatuspersonnel: (state, action) => {
            state.personnel = !state.personnel;
            console.log('state.personnel :', state.personnel);
        },

        setIdLabel: (state, action) => {
            state.id_label = action.payload;
        },

        reinitialiseLabel: (state, action) => {
            state.label = [];
        },

        reinitialiseChecklist: (state, action) => {
            state.checklist = [];
            state.membreChecklist = [];
            state.descriptionLabel = null;
            state.deadline = null;
            state.nameLabel = null;
            state.afficheDeadline = null;
        },

        changeColonneLabel: (state, action) => {
            let labels =action.payload.labels, id_colonne_source = action.payload.source, 
            id_colonne_dest = action.payload.destination , id_label = parseInt(action.payload.id_label);
            let label = labels[id_colonne_source].cards.find(element => element.id === id_label);
            let new_source_cards = labels[id_colonne_source].cards.filter(element => element.id !== id_label);
            let newLabels = produce(labels, (draft) => {
                draft[id_colonne_source].cards = new_source_cards;
                draft[id_colonne_dest].cards.push(label)
            })
            state.label = newLabels;
        },

        setChecklist: (state, action) => {
            state.checklist = action.payload.checklist;
            state.membreChecklist = action.payload.membreChecklist;
            state.descriptionLabel = action.payload.description;
            state.nameLabel = action.payload.nameLabel;
        },

        setMembreChecklist: (state, action) => {
            const {newMembre , anciens} = action.payload;
            let newMembres = produce(anciens, (draft) => {
                newMembre.map((membre) => {
                    draft.push(membre);
                    return true;
                })
            })
            state.membreChecklist = newMembres;
        },

        setEquipe: (state, action) => {
            state.equipeProjet = action.payload;
        },

        setDescription: (state, action) => {
            state.descriptionLabel = action.payload;
        },

        changeCheckedChecklist: (state, action) => {
            let {check, checklist } = action.payload;
            state.checklist = produce(checklist, (draft) => {
                draft = draft.map((element) => {
                    if(element.id === check.id) {
                        element.isChecked = element.isChecked === 1 ? 0 : 1;
                    } 
                    return element;
                });
            });
        },

        notificationChangeStatusChecklist: (state, action) => {
            let {check, checklist } = action.payload;
            checklist.map((element) => {
                if(element.id === check.id) {
                    element.isChecked = element.isChecked === 1 ? 0 : 1;
                }  
                return element;
            })
        },
        
        formatAffichageDeadline : (state, action) => {
            const moisFr = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
            if(action.payload) {
                const deadline = new Date(action.payload);
                state.deadline = action.payload ;
                state.afficheDeadline = deadline ?  deadline.getDate() + " " + moisFr[deadline.getMonth()] + " " + (deadline.getHours().toString().length >1 ? deadline.getHours(): "0"+ deadline.getHours()) +":"+(deadline.getMinutes().toString().length >1 ? deadline.getMinutes(): "0"+ deadline.getMinutes()) : "";
            }
            else{
                state.afficheDeadline = ""
            }
        },

        setLabels: (state, action) => {
            state.labels = action.payload;
        }
        
    },
    extraReducers: {
        [getNotifications.pending]:(state, action) => {
            state.status = "loading";
        },
        [getNotifications.fulfilled]: (state, action) => {
            state.status = "success";
            state.notifications = action.payload;
            let notificaionNotView = state.notifications.filter((notification) => notification.etat ===0);
            state.nbrNotification = notificaionNotView.length;
        },
        [getProjets.pending]:(state, action) => {
            state.status = "loading";
        },
        [getProjets.fulfilled]: (state, action) => {
            state.status = "success";
            state.projet = action.payload;
        },
        [getProjet.pending]:(state, action) => {
            state.status = "loading";
        },
        [getProjet.fulfilled]: (state, action) => {
            state.status = "success";
            state.detailsProjet = action.payload;
            state.id_projet = action.payload.id;
        },
        [getProjets.rejected]: (state, action) => {
            state.status = "failed";
        },
        [getProjetsSuperUser.pending]:(state, action) => {
            state.status = "loading";
        },
        [getProjetsSuperUser.fulfilled]: (state, action) => {
            state.status = "success";
            state.projetUser = action.payload;
        },
        [getProjetsSuperUser.rejected]: (state, action) => {
            state.status = "failed";
        },
        [createTicket.fulfilled]: (state, action) => {
            state.status = "success";
            state.id_ticket = action.payload.data.id;
        },
        [createTicket.rejected]: (state, action) => {
            state.status = "failed";
        },
        [createChecklist.fulfilled]: (state, action) => {
            state.status = "success";
            state.checklist.push(action.payload);
        },
        [createChecklist.rejected]: (state, action) => {
            state.status = "failed";
        },
        [saveChangeModalKanban.fulfilled]: (state, action) => {
            state.status = "success";
        },
        [saveChangeModalKanban.rejected]: (state, action) => {
            state.status = "failed";
        },
        [getLabels.fulfilled]: (state, action) => {
            let initialLabels=[
                {id:`${0}`,title:"Entrée",allowcreatecard:true, save: "Validé", isDropDisabled: false, cards:[
                ]},
                {id:`${1}`,title:"À faire",allowcreatecard:false, save: "Validé", isDropDisabled: false, cards:[
                ]},
                {id:`${2}`,title:"En cours",allowcreatecard:false, save: "Terminé", isDropDisabled: true, cards:[
                ]},
                {id:`${3}`,title:"Terminé",allowcreatecard:false, save: "Validé", isDropDisabled: true, cards:[
                ]},
                {id:`${4}`,title:"Collaboration",allowcreatecard:false, save: "Validé", isDropDisabled: false, cards:[
            ]}]
            const data = action.payload
            
            for(let i = 0; i<data.length; i++ ){
                if(data[i].colonne_id_id === 1){
                    initialLabels[0].cards.push(data[i]);
                }
                else if(data[i].colonne_id_id === 2){
                    initialLabels[1].cards.push(data[i]);
                }
                else if(data[i].colonne_id_id === 3){
                    initialLabels[2].cards.push(data[i]);
                }
                else if(data[i].colonne_id_id === 4){
                    initialLabels[3].cards.push(data[i]);
                }
                else if(data[i].colonne_id_id === 5){
                    initialLabels[4].cards.push(data[i]);
                }
            }
            state.labels = initialLabels;
            state.status = "success";
        },
        [getLabels.rejected]: (state, action) => {
            state.status = "failed";
        },
        
    }
})

export const {incrementNotification, notificationChangeStatusChecklist, setLabels, formatAffichageDeadline, setIdLabel, changeCheckedChecklist, setDescription, setMembreChecklist, setEquipe, definirProjet, reinitialiseLabel, changeColonneLabel, reinitialiseChecklist, setChecklist, changeStatuspersonnel } = projetSlice.actions;
