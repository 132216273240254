import React,{Fragment, useState} from "react"
import {Modal,Box, Grid, Typography, Button,TextField} from "@mui/material"
import { Checkbox } from "@mui/material";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector} from "react-redux";



import Style from "./Style"
import { createChecklist } from "../../toolkit/projet";

const ChecklistUpdate = ({ isOpen, handleClose }) =>{
     const dispatch = useDispatch();
     const id_label = useSelector(state => state.projet.id_label);

     const addChecklistState= async (data)=>{
          dispatch(createChecklist({checklist:data.checkl, label_id: id_label}));
          handleClose();
     }

     /*validation form checklict*/
     const validationSchema = Yup.object().shape({
          checkl: Yup.string()
          .required('Ce champ est obligatoire')
     });
     const {
          register,
          handleSubmit,
          formState: { errors }
     } = useForm({
          resolver: yupResolver(validationSchema)
     });
     return(
          <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"               
          >
               <Box sx={Style}>
                    <Grid container>
                         <Grid item xs={11}>
                              <Typography color='#4834d4' sx={{fontSize:'18px'}} id="modal-modal-title" variant="h8" component="h2">
                                   <Checkbox defaultChecked />
                                        Modifier le checklist
                              </Typography>
                         </Grid>
                         <Grid item xs={1}>
                              <CloseIcon style={{marginLeft:'20%',cursor:'pointer',color:'red'}} sx={{fontSize:'medium'}} onClick={handleClose}/>     
                         </Grid>
                    </Grid>
                    <form sx={{ width: 300}} onSubmit={() => handleSubmit(addChecklistState)}>
                         <Grid container>
                              <Grid item xs={12} lg={12} md={12}>
                                   <TextField {...register('checkl')} size='small'  label="Nouveau checklist" variant="outlined" sx={{ m: 1, width: '100%' }}/>
                              </Grid>
                         </Grid>
                         <Grid container>
                              <Grid item xs={12}>
                                   <Button type='submit' variant='contained' sx={{ m: 1, width: '100%' }}>
                                        Modifier
                                   </Button>
                              </Grid>
                         </Grid>
                    </form>
               </Box>
          </Modal>
     )
}

export default ChecklistUpdate;