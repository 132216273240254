import React,{Fragment, useState} from "react"
import {Modal,Box, Grid, Typography, Button,TextField} from "@mui/material"
import { Checkbox } from "@mui/material";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import StyledButton from "./StyledButton";
import Style from "./Style"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createChecklist } from "../../toolkit/projet";
import { useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/styles"


const Styleddivone=styled('div')({
     alignItems:'center',
     display:'flex'
})

const StyleddivTwo=styled('div')({
     align:'left'
})

const ChecklistModal = () =>{
     const dispatch = useDispatch();
     const [openChecklist,setOpenChecklist]=useState(false);
     const handleOpenChecklist = () => setOpenChecklist(true);
     const handleCloseChecklist= () => setOpenChecklist(false);
     const id_label = useSelector(state => state.projet.id_label);
     const addChecklistState= async (data)=>{
          dispatch(createChecklist({checklist:data.checkl, label_id: id_label}));
          handleCloseChecklist();
     }

     /*validation form checklict*/
     const validationSchema = Yup.object().shape({
          checkl: Yup.string()
          .required('Ce champ est obligatoire')
     });
     const {
          register,
          handleSubmit,
          formState: { errors }
     } = useForm({
          resolver: yupResolver(validationSchema)
     });
     return(
          <Fragment>
               <StyledButton
                    onClick={handleOpenChecklist}
                    variant='contained'
               >
                    <Styleddivone>
                              <StyleddivTwo>
                                   <CheckBoxIcon/>
                              </StyleddivTwo>
                              Checklist
                         </Styleddivone>
               </StyledButton>
               <Modal
                    open={openChecklist}
                    onClose={handleCloseChecklist}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"               
                >
                    <Box sx={Style}>
                         <Grid container>
                              <Grid item xs={11}>
                                   <Typography color='#4834d4' sx={{fontSize:'18px'}} id="modal-modal-title" variant="h8" component="h2">
                                        <Checkbox defaultChecked />
                                             Ajouter des checklists
                                   </Typography>
                              </Grid>
                              <Grid item xs={1}>
                                   <CloseIcon style={{marginLeft:'20%',cursor:'pointer',color:'red'}} sx={{fontSize:'medium'}} onClick={handleCloseChecklist}/>     
                              </Grid>
                         </Grid>
                         <form sx={{ width: 300}} onSubmit={handleSubmit(addChecklistState)}>
                              <Grid container>
                                   <Grid item xs={12} lg={12} md={12}>
                                        <TextField {...register('checkl')} size='small'  label="Nouveau checklist" variant="outlined" sx={{ m: 1, width: '100%' }}/>
                                   </Grid>
                              </Grid>
                              <Grid container>
                                   <Grid item xs={12}>
                                        <Button type='submit' variant='contained' sx={{ m: 1, width: '100%' }}>
                                             Ajouter
                                        </Button>
                                   </Grid>
                              </Grid>
                         </form>
                    </Box>
               </Modal>
          </Fragment>
     )
}

export default ChecklistModal;