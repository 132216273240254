import  React,{useState,useEffect} from "react"
import {Box, Grid} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector,useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import {styled} from "@mui/styles"
import {faTable,faTicketSimple,faAngleDoubleDown,faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import {Color} from "../../components/palette/color"
import { getProjetsSuperUser} from "../../toolkit/projet";
import CardMenu from '../../components/Cards/CardMenu/CardMenu'
import Layout from "../layout/layout"
import { Styleddivone } from "../../components/Modal/Membre";
import { StyleddivTwo } from "../../components/Modal/Membre";


const StyledTitleOne=styled('p')({
    color:'#222f3e',
    fontSize:'14px',
    fontWeight:700,
    marginBottom:'5%'
})

const StyledGreatTitleOne=styled('h2')({
    color:'#34495e',
    fontSize:'22px',
    fontWeight:900,
    marginLeft:'2%',
    width:'100%',
    
})

const useStyles = makeStyles((theme)=>({
  root: {
    height:'100vh',
   fontFamily:'Arial',
   justifyContent:'center',
   textAlign:'justify',
   background:"url('/images/bacc.jpg')",
   backgroundRepeat:'no-repeat',
   backgroundSize:'cover',
    padding:'60px 0px 60px 0px',
    '& .head-title':{
        color:'#172B4D'
    },
    '& .p-selected':{
        color:'#091E42A8',
        fontSize:'15px',
    },
  },
  container:{
    margin:'0px 0px 0px 20px',
  },
  containerFormControl:{
      margin:'-10px 0px 0px 0px'
   },
   sect1:{
    alignItems:'center'
   }
}));
export default function HomeContainer() {
  const classes = useStyles();
  
  const [modele, setModele] = React.useState('');
  
  const [isFilter, setIsFilter] = React.useState(false)
  const [indexProject, setIndexProjet] = useState(8)

  const [isLoadingProject, setIsLoadingProjet] = useState(false)

  const [operationProject,seOperationProject] = useState ('+')

  const dispatch=useDispatch()

  const handleChange = (event) => {
    setModele(event.target.value);
    if(event.target.value.length > 1){
        setIsFilter(true)
        setIsLoadingProjet(true)
    }else{
        setIsFilter(false)
        setIsLoadingProjet(false)
        setIndexProjet(8)
        seOperationProject('+')
    }
    };

    useEffect(()=>{
        dispatch(getProjetsSuperUser(localStorage.getItem('user-this_id')));
    },[dispatch])

    const { projetUser } = useSelector(state => state.projet);
    const handlePlusProjet = () =>{
    let newIndex = 0
    if(operationProject === '+'){
        newIndex = indexProject + 8
    }else{
        if(indexProject > 8){
            newIndex = indexProject - 8
            if(newIndex === 8){
                seOperationProject('+')
            }
        }else{
            seOperationProject('+')
            newIndex = indexProject + 8
        }
    }
    setIsLoadingProjet(true)
    if(projetUser.length - newIndex < 0){
        seOperationProject('-')
    }
    setTimeout(() => {
        setIndexProjet(newIndex)
        setIsLoadingProjet(false)
    }, 1000)
}

return (
<Layout>
    <Grid container className={classes.root}>
        <Grid item lg={9}  xl={9} md={8} sm={12} xs={12}>
            <Grid container spacing={2}>
                <Grid container className={classes.sect1}>
                    
                </Grid>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid container className={classes.sect1}>
                        <Styleddivone>
                                <StyleddivTwo>
                                    <FontAwesomeIcon icon={faTable} style={{color:`${Color.primary}`,width:'100%',height:'40px'}}/>
                                </StyleddivTwo>
                                <StyledGreatTitleOne>
                                    Mes projets
                                </StyledGreatTitleOne>
                        </Styleddivone>
                        </Grid>
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={1}>
                            {projetUser.length > 0 ?
                        <Grid container >
                            {
                            !isFilter ? projetUser.filter((element,index) => element && index < indexProject  )?.map((element,i)=>{
                                    if(element){
                                        return(
                                            <Grid item  lg={3} xl={3} md={6} sm={4}  xs={12} key={`${element.name + i}`}>
                                                <CardMenu menu={element} />
                                            </Grid>
                                        )
                                    }
                            }) : projetUser?.filter((element,index) => element && element?.name?.toLowerCase().search(modele?.toLowerCase()) !== -1)?.map((element,j)=>{
                                if(element){
                                    return(
                                        <Grid item  lg={3} xl={3} md={6} sm={4}  xs={12} key={`${element.name + j}`}>
                                            <CardMenu menu={element} />
                                        </Grid>
                                    )
                                }
                        })
                            }
                            <Grid mt={4} mb={3} item textAlign='center' lg={12} xl={12} md={12} sm={12}  xs={12}>
                                {isLoadingProject ?
                                <Box sx={{ display: 'flex',textAlign:'center', justifyContent:'center' }}>
                                    <CircularProgress />
                                </Box> 
                                :
                                (!isFilter && projetUser.length > 0 ?<Box>
                                    {operationProject === '+' ? <FontAwesomeIcon icon={faAngleDoubleDown} style={{color:`${Color.primary}`,width:'100%',height:'20px',cursor:'pointer'}} onClick={() => handlePlusProjet()}/> : <FontAwesomeIcon icon={faAngleDoubleUp} style={{color:`${Color.primary}`,width:'100%',height:'20px',cursor:'pointer'}} onClick={() => handlePlusProjet()}/>}
                                </Box> : null)
                                
                                }
                            </Grid>
                        </Grid>
                        :
                        <Box sx={{ display: 'flex',textAlign:'center', justifyContent:'center' }}>
                            <CircularProgress />
                        </Box>
                        }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
</Layout>
  );
}