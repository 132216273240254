import { Redirect, Route } from "react-router-dom";
import axios from "axios";

const ProtectedRoute = props => {
    const token = localStorage.getItem('user-token') ? localStorage.getItem('user-token') : false;
    axios.defaults.headers.common['Authorization'] = token;
    if(!token) {
        return <Redirect to="/" />
    }
    return <Route {...props} />
}

export default ProtectedRoute