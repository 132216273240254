import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import io from 'socket.io-client';

export const initialState = {
    users: [],
    status: null,
    url: process.env.REACT_APP_SERVER_API,
}

//export const socket = io(process.env.REACT_APP_SERVER_API);

export const socket = io(process.env.REACT_APP_SERVER_DOMAINE, {
    path: process.env.REACT_APP_SERVER_SOCKET
  });

const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
};

export const getUsers = createAsyncThunk(
    "users/getUsers",
    async(dispatch, getState) => {
        return await fetch(initialState.url +"checklist/", requestOptions).then(
            (res) => {
                return res.json()
            } 
        )
    }
)

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    extraReducers: {
        [getUsers.pending]:(state, action) => {
            state.status = "loading";
        },
        [getUsers.fulfilled]: (state, action) => {
            state.status = "success";
            state.users = action.payload;
        },
        [getUsers.rejected]: (state, action) => {
            state.status = "failed";
        }
    }
})