import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import WorkIcon from '@mui/icons-material/Work';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



const Notification =({selectNotification, anchorEl, handleClose, data })=>{
 
  const open = Boolean(anchorEl);
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/') ;
  }

  function formatTime(date) {
    return [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes())
    ].join(':') ;
  }

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflowY: data.length >= 6 ? 'scroll' : 'hidden',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
          style: {
            maxHeight: '35ch',
            width: '35ch',
            wordWrap: 'break-word'
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {data.map((item,index) =>(<MenuItem key={index.toString()} style={{ backgroundColor: item.etat === 0? "#C0C0C0" : "#FFFFFF",padding: '0px'}}>
            <ListItem spacing={1} onClick={() => selectNotification(item)}>
                <ListItemAvatar>
                    {item.type === "Carte en retard"  && <Avatar  sx={{ bgcolor: 'red' }}>
                        <WarningIcon/>
                    </Avatar>}
                    {item.type === 1 && <Avatar sx={{ bgcolor: 'blue' }}>
                        <WorkIcon color='red'/>
                    </Avatar>}
                    {item.type === "Carte terminé" && <Avatar sx={{ bgcolor: 'green' }}>
                        <CheckCircleIcon />
                    </Avatar>}
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={formatDate(new Date(item.deadline)) + ' à ' + formatTime(new Date(item.deadline))} />
            </ListItem>
            <Divider />
        </MenuItem>))}
      </Menu>
    </React.Fragment>
  );
}

export default Notification