import {useState} from "react"
import {styled} from "@mui/styles"
import {faPen} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid} from "@mui/material"
import {ClearcardIcon} from "../../components/Cards/DndCard/TicketCard"


import {Color} from "../../components/palette/color"

const UpdatecardButton = styled('button')({
    '&:hover': {
         background: Color.primary,
    },
    cursor:'pointer',
    marginRight:0,
    width: '100%',
    marginBottom: 12,
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 30,
});

const LabelContainer = styled('div')({
        alignItems:'center',
        display:'flex',
        width:'90%'
})

const StyledLabel = styled('div')({
    display:'inline-block',
    position:'relative',
    borderRadius:'3px',
    padding:'0 12px',
    maxWidth:'100%',
    minWidth:'100%',
    height:'32px',
    boxSizing:'border-box',
    lineHeight:'32px',
    color:'#555',
    fontSize:'12px',
    fontWeight:'400',
    textAlign:'left',
    textOverflow:'ellipsis',
    overflow:'hidden',
    whiteSpace:'nowrap',
    marginTop:'2%'
})

export const StyledCheckbox = styled('input')({
     boxSizing:'border-box',
     color:'#5e6c84',
     fontSize:'12px',
     fontWeight:'700',
     borderRadius:'3px',
     padding:'8px 12px',
     boxShadow:'#dfe1e6',
     backgroundColor:'#fbfcfd',
     width:'16px',
     height:'16px'
})

const StyledInput = styled('input')({
     outline:'none',
     border:'2px solid #aaa',
     boxSizing:'border-box',
     color:'#17b4d',
     fontSize:'12px',
     lineHeight:'20px',
     fontWeight:'400',
     borderRadius:'3px',
     padding:'8px 12px',
     boxShadow:'#dfe1e6',
     backgroundColor:'#fbfcfd',
     width:'100%',
     height:'32px'

})

const LabelCard = ({bgcolor}) =>{
    const [edit,setEdit] = useState(false)

    const HandleEdit = () =>{
        setEdit(true)

    }
    const HandleCloseEdit = () =>{
        setEdit(false)
    }

    return(
        <>
            <LabelContainer>
                <StyledCheckbox type="checkbox"/>
                    {edit === true ? <> 
                        <Grid container spacing={1} style={{marginTop:'2%'}}>
                                    <Grid item xs={8}>
                                        <StyledInput autoFocus style={{backgroundColor:bgcolor}}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                            <UpdatecardButton style={{backgroundColor:Color.primary,color:'white'
                                                }}>
                                                Modifier
                                            </UpdatecardButton>
                                    </Grid>
                                    <Grid  item xs={1/2} ml={1/2} onClick={HandleCloseEdit}>
                                        <ClearcardIcon/>
                                   </Grid>
                              </Grid>
                        </> :
                        <>
                            <StyledLabel style={{backgroundColor:bgcolor}}>hey</StyledLabel>
                            <FontAwesomeIcon onClick={()=>HandleEdit()} icon={faPen} style={{marginLeft:'1%',cursor:'pointer'}}/>
                        </>
                       } 
                
            </LabelContainer>
        </>
    )
}

export default LabelCard;