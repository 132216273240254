import React, { Fragment, useState } from "react"
import { makeStyles } from "@mui/styles"
import Ticketcard from "./TicketCard"
import styled from "@emotion/styled"
import { Button, Typography, Grid, Box } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Droppable } from "react-beautiful-dnd"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SaveIcon from '@mui/icons-material/Save';
import {Color} from "../../../components/palette/color"

const AddCardButton = styled(Button)({
     background: '#ebecf0',
     width: '100%',
     marginBottom: 12,
     border: 0,
     borderRadius: 3,
     color: '#636e72',
     height: 24,
     justifyContent: 'left',
     textTransform: 'lowercase'
});

const PushcardButton = styled(Button)({
     '&:hover': {
          background: '#1B1464',
     },
     background: Color.primary,
     width: '100%',
     marginBottom: 12,
     border: 0,
     borderRadius: 3,
     color: 'white',
     height: 30,
     textTransform: 'uppercase',
});

const ColumnTitle = styled(Typography)({
     color: '#172b4d',
     fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,Droid Sans,Helvetica Neue,sans-serif',
     fontSize: '14px',
     fontWeight: '700',
     lineHeight: '20px',
     marginBottom: '4%'
})
//"#4834d4"
const useStyle = makeStyles({
     container: {
          background: '#ebecf0',
          width: 230,
          borderRadius: 3,
          padding: 8,
          marginLeft: '2%'
     },
     input: {
          borderRadius: 3,
          border: 'none',
          width: '97%',
          height: 60,
          marginBottom: 12,
          resize: 'none',
     }
})

const ClearcardIcon = styled(ClearIcon)({
     color: '#fff',
     marginLeft: 2,
     cursor: 'pointer',
     background: 'red',
     marginTop: '0px',
     padding: '2.8px',
     borderRadius: '5px',
     '&:hover': {
          opacity: 0.8
     }
})

const Save = styled(SaveIcon)({
     '&:hover': {
          background: '#dcdde1'
     },
     cursor: 'pointer',
})

const TicketBoard = ({ save, isDropDisabled, listId, title, allowcreatecard, cards, createCard }) => {
     const style = useStyle()
     const [showform, setShowform] = useState(false)
     const [titre, setTitre] = useState("")
     const Handleshowform = () => {
          setShowform(!showform)
     }
     const Hadlecloseform = () => {
          setShowform(false)
     }

     
     return (
          <Fragment>
               <Droppable isDropDisabled={isDropDisabled} droppableId={String(listId)}>
                    {(provided) => (
                         <div
                              {...provided.droppableProps}
                              className={style.container}
                              ref={provided.innerRef}
                         >
                              <Grid container>
                                   <Grid item xs={10} lg={10}>
                                        <ColumnTitle>{title}</ColumnTitle>
                                   </Grid>
                                   <Grid item xs={2} lg={2}>
                                        <MoreHorizIcon sx={{ cursor: 'pointer' }} />
                                   </Grid>
                              </Grid>

                              <Box sx={{ maxHeight: '480px', overflowY: cards.length >= 4 ? 'scroll' : 'hidden'}}>
                                   {cards.map((card, index) => (
                                        <Ticketcard isScroll ={ cards.length >= 4 ? true : false } save={save} key={card.id} card={card} index={index} id={card.id} />
                                   ))}
                                   {allowcreatecard && (
                                        <div key={title}>
                                             {!(showform) && (
                                                  <Grid container mt={2}>
                                                       <Grid item xs={10} lg={10}>
                                                            <AddCardButton onClick={Handleshowform}>
                                                                 <AddIcon />
                                                                 {"  "}ajouter une carte
                                                            </AddCardButton>
                                                       </Grid>
                                                       <Grid item xs={2} lg={2}>
                                                            <Save onClick={Handleshowform} />
                                                       </Grid>
                                                  </Grid>
                                             )}
                                             {showform && (
                                                  <>
                                                       <textarea
                                                            autoFocus
                                                            className={style.input}
                                                            type="text"
                                                            placeholder="Tapez ici sa description"
                                                            value={titre}
                                                            onChange={(e) => setTitre(e.target.value)}
                                                       />
                                                       <Grid container>
                                                            <Grid item xs={8}>
                                                                 <PushcardButton onClick={(e) => {
                                                                      createCard(titre);
                                                                      setTitre("");
                                                                      setShowform(!showform)
                                                                 }}
                                                                 >
                                                                      Valider
                                                                 </PushcardButton>
                                                            </Grid>
                                                            <Grid ml={3} item xs={2} onClick={Hadlecloseform}>
                                                                 <ClearcardIcon />
                                                            </Grid>
                                                       </Grid>
                                                  </>
                                             )}
                                        </div>
                                   )}
                              </Box>
                              {provided.placeholder}
                         </div>
                    )}
               </Droppable>
          </Fragment>
     )
}

export default TicketBoard;