
import Header from '../../components/Headers/Headers'
import Footer from '../../components/Footers/Footer'
import {useSelector} from "react-redux"
import User from '../user';

export default function Layout({ children, home }) {
  const personnelView = useSelector(state => state.projet.personnel);
  return (
      <>
        <Header/>
          <main>
            {personnelView ? <User/> : children }
          </main>
        <Footer/>
      </>
  )
}