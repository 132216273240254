import TableauBody from "../../components/Cards/tableau/tableauBody";
import {makeStyles} from '@mui/styles'
import {Container} from "@mui/material"
import Tablayout from "../layout/tableaulayout";
import {useSelector} from "react-redux"
import User from '../user';

const useStyles=makeStyles({
  root:{
    backgroundImage: "url(https://trello-backgrounds.s3.amazonaws.com/SharedBackground/1920x1920/429104d8c05d4d50ece90d9e636d9c74/photo-1646605381573-e3a846cf6be8.jpg)",
    backgroundSize:'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    marginTop:0,
    overflowX:'hidden'
  }
})

export default function Tableau() {
  const classe=useStyles();
  const personnelView = useSelector(state => state.projet.personnel);
  return (
    <>
    <Tablayout>
    {personnelView ? <User/> :
      <main  className={classe.root}>
        <Container>
        {/* 
        <TableauHeader/>
        */}
        </Container>
         <TableauBody/> 
      </main>
    }
    </Tablayout>
    </>
  );
}
