import WorkspaceHead from "../../components/Cards/workspace/workspaceHeader";
import WorkspaceBody from "../../components/Cards/workspace/workspaceBody";
import Layout from '../layout/layout'



export default function Workspace(){
     return(
          <Layout>
          <div className="container">
          <header>
            <title>TICKETING</title>
            <link rel="icon" href="/favicon.ico" />
          </header>
          <main className="container">
            <WorkspaceHead/>
            <WorkspaceBody/>
          </main>
    
          <style jsx>{`
            .container {
              min-height: 100vh;
            }
          `}</style>
    
          <style jsx global>{`
            html,
            body {
              padding: 0;
              margin: 0;
              font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                sans-serif;
            }
            * {
              box-sizing: border-box;
            }
          `}</style>
        </div>
      </Layout>
     )
}