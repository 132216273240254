import * as React from 'react';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles'
import { Container, Grid } from '@mui/material'
import { Typography } from '@mui/material'
import { Card } from '@mui/material'
import { Box} from '@mui/system'
import { Stack } from '@mui/material'
import { Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { initialState } from '../../toolkit/user';
import { useParams } from 'react-router-dom';
import {Color} from "../palette/color"



const useStyles=makeStyles({
  root:{
    backgroundColor:"#dfe6e9",
  },
  content:{
    fontSize:5,
    marginLeft:'100%',
  },
  Container:{
    padding:'0px 20px 20px 20px'
  },
  imagefond:{
    width:'100%',
    minHeight:'150px',
    maxHeight:'150px'
  },
  choice:{
    cursor:'pointer'
  }

 
  
})
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
     return {
       fontWeight:
         personName.indexOf(name) === -1
           ? theme.typography.fontWeightRegular
           : theme.typography.fontWeightMedium,
     };
   }

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function Createquipemenu() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [names, setNames] = useState(null)
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    handleCloseSelect()
  };
  /*
  const equipeProjet = useSelector(state => state.projet.equipeProjet);
  const anciensEquipe = (equipeProjet) => {
    console.log(equipeProjet)
    if(equipeProjet.length){
      console.log("YESSSSSSSSSSSSS")
      console.log(equipeProjet)
      let equipe = [];
      equipeProjet.map((membre) => {
        equipe.push({name: membre.last_name, id: membre.id})
      })
      setPersonName(equipe);
    }
  }
  
  React.useEffect(() => {
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!')
      anciensEquipe(equipeProjet);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  */
  const handleDeselect = (part)=>{
    let newPersname=personName.filter(name=>name!==part)
    setPersonName(newPersname);
   
  }


  const classe=useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { id }= useParams();
  
  const [openSelect, setOpenSelect] = React.useState(false);

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  const handleOpenSelect = () => {
    setOpenSelect(true);
  };

  const handleClick = async(event) => {
    setAnchorEl(event.currentTarget);
    try {
      const { data } = await axios.get(initialState.url +"user/list/false");
      setNames(data);
    } catch (error) {
      console.log(error)
    }
  };
  
  const creerEquipe = () => {
    if(personName.length){
      const membre = personName.map((element) => element.id);
      axios.post(initialState.url + "equipe", {project_id:id, membre}).then((res)=>{
        if(res.data) {
          alert("Equipe créée");
        }
        else{
          alert("Equipe non créée");
        }
      })
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }



  return (
    <div style={{fontSize:12}}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{fontSize:'12px',
        textAlign:'center',
        fontWeight:700,
        marginLeft:'20%',
        color:'#fff',backgroundColor:Color.primary,
        textTransform: "capitalize"}}
      >
        Equipe
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
      <Card  className={classe.root}  sx={{ minWidth: 275,maxWidth:275 }}>
        <Stack>
        <Container sx={{textAlign:'center'}}>
          <Typography >
            Créer une equipe
          </Typography>
          </Container>
        </Stack>
        <Divider/>
        <Box className={classe.Container}
        >
          <FormControl sx={{ m: 1, width: '100%', mt: 3 }}>
        <Select
          multiple
          displayEmpty
          value={personName}
          onChange={handleChange}
          open={openSelect}
          onClose={handleCloseSelect}
          onOpen={handleOpenSelect}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            return <em>Choisissez des membres</em>;
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label'}}
        >
          <MenuItem disabled value="">
            <em>Membres</em>
          </MenuItem>
          {names?.map((name, index) => (
            <MenuItem
              key={index}
              value={{"name":name.lastName, "id": name.id}}
              style={getStyles(name.lastName, personName, theme)}
            >
              {name.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{maxHeight:'200px', overflowY : personName.length > 4 ? 'scroll' : 'hidden'}}>
        {personName.map((part, index)=>(
          <span  key={index}>
                <div style={{backgroundColor:'#dfe6e9',width:'80%', marginLeft:'8%', padding:'10px 10px',borderRadius:'10px 10px',marginTop:'1%'}}>
                  <Grid container>
                    <Grid item xs={11}>
                      {part.name}
                    </Grid>
                    <Grid item xs={1}>
                      <CloseIcon onClick={()=>handleDeselect(part)} style={{cursor:'pointer'}} />
                    </Grid>
                  </Grid>
                </div>
          </span>
        ))}
      </div>
      <Stack sx={{marginTop:'8%'}}>
                <Button 
                variant='contained'
                size="large"
                onClick={creerEquipe}
                >
                  Créer
                </Button>
        </Stack>
                 
        </Box>
      </Card>
      </StyledMenu>
    </div>
  );
}
