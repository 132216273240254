import React,{Fragment, useEffect} from "react"
import TicketBoard from "../DndCard/TicketColumn"
import {Grid,Typography} from "@mui/material"
import { DragDropContext } from "react-beautiful-dnd"
import {useSelector,useDispatch} from "react-redux"
import {styled} from "@mui/styles"
import { useParams } from "react-router-dom"
import axios from "axios";
import produce from "immer";
import { initialState } from "../../../toolkit/user"
import {getNotifications, getLabels, getProjet, setEquipe, setLabels, notificationChangeStatusChecklist, incrementNotification} from "../../../toolkit/projet"
import { socket } from "../../../toolkit/user"

const ProjectTitle = styled(Typography)({
     color:'white',
     fontWeight:'bolder',
     padding:'20px',
     marginLeft:"10%",
})

export default function TableauBody(){
     const { id }= useParams();
     const dispatch = useDispatch();
     const detailsProjet = useSelector(state => state.projet.detailsProjet);

     var labels = useSelector(state => state.projet.labels);
     var idAdmin = localStorage.getItem('user-this_id');
     const {checklist, nbrNotification, notifications}  = useSelector(state => state.projet);
     useEffect(() => {
          dispatch(getProjet(id));
          dispatch(getLabels(id));
          const getEquipe = async(id) => {
               const { data } = await axios.get(initialState.url +"equipe/membre/"+id);
               dispatch(setEquipe(data));
          }
          getEquipe(id);
          socket.on('connect', () => {
               console.log("setIsConnected(true)");
          });
     
          socket.on('disconnect', () => {
               //console.log("DisConnected(true)");
          });

          socket.on('eventTest', (data) => {
               console.log("Websocket event : eventTest");
               console.log(data);
          });

          socket.on('notificationAdmin'+idAdmin, (data) => {
               /*
               if(data.type === "Changement status d'un checklist"){
                    // Changer l'etat d'un checklist
                    //dispatch(notificationChangeStatusChecklist({check:data, checklist}));
               }
               */
               if(data.type === "Carte terminé" || data.type ==="Carte en retard") {
                    //dispatch(incrementNotification({nbrNotification, notifications, notificaion: data}))
                    dispatch(getNotifications(localStorage.getItem('user-this_id')));
                    dispatch(getLabels(id));
               }
          })
     
          return () => {
               socket.off('connect');
               socket.off('disconnect');
               socket.off('eventTest');
          };
     }, [dispatch, id, idAdmin, checklist, notifications, nbrNotification]);

     const onDragEnd = async (result) =>{
          const { destination, source, draggableId} = result;
          if (!destination) {
               return;
          }
          let id_colonne_source = source.droppableId, id_colonne_dest = parseInt(destination.droppableId) , id_label = parseInt(draggableId);
          let label = labels[id_colonne_source].cards.find(element => element.id === id_label);
          try {
               axios.put(initialState.url +"label/"+id_label+"/", {
                    "colonneIdId": id_colonne_dest+1,
                    "name": label.name
               })
               
               let new_source_cards = labels[id_colonne_source].cards.filter(element => element.id !== id_label);
               let newLabels = produce(labels, (draft) => {
                   draft[id_colonne_source].cards = new_source_cards;
                   draft[id_colonne_dest].cards.push(label)
               })
               dispatch(setLabels(newLabels));
          } catch (error) {
               console.log(error)
          }

     }

     const createCard = async (titre) => {
          try {
               const { data } = await axios.post(initialState.url +"label/", {
                    "name": titre,"etat": 0,"projectIdId": id, "colonneIdId": 1, "description": "", "userIdId": localStorage.getItem('user-this_id'), "createDate": new Date()
               })
               let newLabels = produce(labels, (draft) => {
                    draft[0].cards.push(data)
               })
               dispatch(setLabels(newLabels));
          } catch (error) {
               console.log(error)
          }
     }

     return(
          <Fragment>
               <DragDropContext onDragEnd={onDragEnd}>
                    <div style={{maxWidth:'100%',minWidth:'80%'}}>
                    <ProjectTitle style={{fontWeight:'bolder'}} variant="h6">{detailsProjet?.name}</ProjectTitle>
                         <Grid container style={{display:'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around'}} >
                              {labels?.map(list=>(
                              <Grid item key={list.id} xs={8} md={4}  lg={2}>
                                   <TicketBoard listId={list.id} cards={list.cards} title={list.title} allowcreatecard={list.allowcreatecard} save={list.save} isDropDisabled={list.isDropDisabled} createCard={createCard}/>
                              </Grid>
                              ))}
                         </Grid>
                    </div>
               </DragDropContext>
          </Fragment>
     )
}
