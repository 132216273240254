import {Fragment, useState} from "react";
import {Container,Stack} from "@mui/material";
import { Grid} from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import {styled} from "@mui/styles"
import GroupsIcon from '@mui/icons-material/Groups';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import { changeCheckedChecklist, setDescription, setChecklist, deleteChecklist } from "../../toolkit/projet";
import {Color} from "../../components/palette/color"
import { StyledCheckbox } from "./labelCard";
import {StyledTitle} from "./label"
import ChecklistUpdate from "./ChecklistUpdate"


const StyledChecklist = styled('div')({
     alignItems:'center',
     display:'flex',
     width:'90%'
})


const StyledFirsttitle=styled('p')({
     color:Color.primary,
     fontSize:"18px",
     fontWeight:'800',
     lineHeight:'20px',
     textAlign:'center'
})


const StyledGreatTitle=styled('p')({
     color:"#172b4d",
     fontSize:"14px",
     fontWeight:'700',
     lineHeight:'20px',
     marginLeft:'5px',
     alignItems:'center'
})

const Styleddivone=styled('div')({
     alignItems:'center',
     display:'flex',
})

const StyleddivoneDeadline=styled('div')({
     alignItems:'center',
     display:'flex',

})

const StyleddivTwo=styled('div')({
     align:'left'
})

const StyledSpan = styled('span')({
     fontSize:'12px',
     width:'auto',
     backgroundColor:Color.primary,
     padding:'3px 3px',
     borderRadius:'10px 10px',
     marginLeft:'1%'

})

const StyledCheck =styled('p')({
     textAlign:'center',
     fontSize:'12px',
     color:'#5e6c84',
     lineHeight:'20px'

})

const StyledSpanMembre = styled('span')({
     fontSize:'10px',
     width:'auto',
     backgroundColor:Color.primary,
     padding:'3px 3px',
     borderRadius:"5px 5px",
     marginLeft:'1%'
})
const StyledSpanChecklist = styled('span')({
     fontSize:'10px',
     width:'auto',
     backgroundColor:Color.primary,
     padding:'3px 3px',
     borderRadius:"5px 5px",
     marginLeft:'1%'
})

const StyledSpecialDivMembre=styled('div')({
     maxWidth:'100%',
     display:'flex'
})

const StyledDescriptionField = styled('textarea')({
     overflow:'hidden',
     overflowWrap:'break-word',
     resize:'none',
     height:'50px',
     minHeight:'50px',
     display:'block',
     borderColor:'#091e4221',
     width:'100%',
     borderRadius:'3px 3px',
     padding:'8px 12px',
     lineHeight:'20px',
     boxSizing:'border-box',
     color:'#172b4d',
     fontSize:'14px',
     fontWeight:'400px',
     marginTop:'2%'

})

const Section1 = () =>{
     const dispatch = useDispatch()
     const {checklist, nameLabel, membreChecklist, descriptionLabel, afficheDeadline }  = useSelector(state => state.projet);
     const [isOpen,setIsOpen] = useState(false)

     const handlesDescription = (event) => {
          dispatch(setDescription(event.target.value));
     }
     const handleChecklistCheck = (check) => {
          dispatch(changeCheckedChecklist({check, checklist}));
     }

     const handleEdit =(id) =>{
          setIsOpen(true)
     }

     const handleClose = () =>{
          setIsOpen(false)
     }
     const handleClear = (id) =>{
          const newCheckList = checklist.filter((el) => el.id !== id)
          dispatch(setChecklist({ checklist: newCheckList }))
          dispatch((deleteChecklist({ id })))
     }
     return(
          <Fragment>
               <StyledFirsttitle>
                    {nameLabel}
               </StyledFirsttitle>
               <ChecklistUpdate handleClose={handleClose} isOpen={isOpen}/>
               <Container>
                    <Stack direction="row">
                              <Styleddivone>
                                   <StyleddivTwo>
                                        <GroupsIcon sx={{ color:'#172b4d' }}/>
                                   </StyleddivTwo>
                                   <StyledGreatTitle>
                                        Membres
                                   </StyledGreatTitle>
                              </Styleddivone>
                    </Stack>
                    <StyledSpecialDivMembre>
                         <Grid container gap={2}>
                              {membreChecklist?.map((membre, index)=>(
                                   <Grid key={index} item xs={12} lg={1}>
                                        <StyledSpanMembre>
                                             {membre.last_name}
                                        </StyledSpanMembre>
                                   </Grid>
                                   ))}
                         </Grid>
                    </StyledSpecialDivMembre>
                    <Stack direction="row" sx={{marginTop:'10px'}}>
                              <Styleddivone>
                                   <StyleddivTwo>
                                        <ArticleIcon sx={{ color:'#172b4d' }}/>
                                   </StyleddivTwo>
                                   <StyledGreatTitle>
                                        Description
                                   </StyledGreatTitle>
                              </Styleddivone>
                    </Stack>
                         <StyledDescriptionField 
                                   placeholder="Ajouter une description plus detaillé"
                                   onChange={handlesDescription}
                                   value={descriptionLabel}
                              />
                         <Styleddivone>
                              <StyleddivTwo>
                                   <ArticleIcon sx={{ color:'#172b4d'}}/>
                              </StyleddivTwo>
                              <StyledGreatTitle>
                                   Listes des checklists
                              </StyledGreatTitle>
                         </Styleddivone>
                         {!(checklist?.length==0) &&(
                              <div>
                                   <Stack direction="row">
                                        <Grid container>
                                             {checklist?.map((check, index)=>(
                                                  <Grid key={index.toString()} item xs={12}>
                                                       <Grid container>
                                                            <Grid key={index} item xs={10}>
                                                                 <Styleddivone>
                                                                      <StyledCheckbox type="checkbox" onChange={()=>handleChecklistCheck(check)} checked={check.isChecked ? true:false}/>
                                                                      <StyledTitle>{check.name}</StyledTitle>
                                                                </Styleddivone>
                                                            </Grid>
                                                            
                                                            <Grid key={index} style={{ paddingTop: '2px'}} item xs={1}>
                                                                 <IconButton onClick={() => handleEdit(check.id)}>
                                                                      <EditIcon fontSize="small" color="info"/>
                                                                 </IconButton>
                                                            </Grid>
                                                            <Grid key={index} style={{ paddingTop: '4px'}} item xs={1}>
                                                                 <IconButton onClick={() => handleClear(check.id)}>
                                                                      <ClearIcon fontSize="small" color="error"/>
                                                                 </IconButton>
                                                            </Grid>
                                                       </Grid>
                                                  </Grid>
                                                  ))}
                                        </Grid>
                                   </Stack>
                              </div>
                         )}
                              <StyleddivoneDeadline>
                                   <QueryBuilderIcon sx={{ color:'#172b4d' }}/>
                                   <StyledGreatTitle>
                                        Deadline: 
                                   </StyledGreatTitle>
                                   <StyledSpan>
                                        {afficheDeadline}
                                   </StyledSpan>
                              </StyleddivoneDeadline>
                              
               
               </Container>
          </Fragment>
     )
}

export default Section1;