import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import ButtonTrier from "../../Button/ButtonTrier"
import ButtonFiltre from '../../Button/ButtonFiltre';
import { Container } from '@mui/material';

const images = [
  {
     url: 'https://imgs.search.brave.com/EIrJjc3k-gIsomhCffkyulmY6vLQ2Y9O0OeDM7Wx-qA/rs:fit:1200:750:1/g:ce/aHR0cHM6Ly9zaWxp/Y2FsaW1lLmNvLmpw/L21haW4vd3AtY29u/dGVudC91cGxvYWRz/LzIwMTgvMDIvYmxh/bmMuanBn',
    title: 'Creer un tableau',
    width: '10%'
  },
  {
    url: 'https://imgs.search.brave.com/4IIB1PSZpjNIWqkkyoNBM4VQVQlLFSa-AFctp_lI-CY/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9zdGF0/aWMud2l4c3RhdGlj/LmNvbS9tZWRpYS8y/ZTUyNjZfMTRiYTQz/MGY1MDU5NDdmYThj/NTE1N2NmYmUzZTQz/OWV-bXYyX2RfNjAw/MF80MDAwX3NfNF8y/LmpwZw',
    title: 'Modele1',
    width: '10%',
  },
  {
     url: 'https://imgs.search.brave.com/dvrH8q7zL1z2Mp_2uJLzENJB4uD85yc8lIR9pggZekk/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9pbWFn/ZXM2LmFscGhhY29k/ZXJzLmNvbS80NjYv/NDY2NDI3LmpwZw',
     title: 'Modele2',
     width: '10%',
    
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export default function WorkspaceBody() {
  return (
    <>
    <div style={{marginLeft:"0%",marginTop:"2%"}}>
    <Container>
      <ButtonTrier/>
      <ButtonFiltre/>
    </Container>
    <Box sx={{mt:"1.2%",ml:"20%",display: 'flex',position: 'relative', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
      {images.map((image) => (
      <a href="/tableau/1">
        <ImageButton
          focusRipple
          key={image.title}
          style={{
            width: image.width,
            height:100,
            marginRight:"0.8%"
            
          }}
        >
        
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {image.title}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
        </a>
      ))}
    </Box>
    </div>
    </>
  );
}
