import { Fragment,useState } from "react"
import {Modal,Typography,Grid,Box,Button} from "@mui/material"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment from 'moment';
import {Divider,TextField} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useDispatch } from "react-redux";
import {styled} from "@mui/styles"

import StyledButton from "./StyledButton";
import Style from "./Style"
import { formatAffichageDeadline } from "../../toolkit/projet";

const Styleddivone=styled('div')({
     alignItems:'center',
     display:'flex'
})

const StyleddivTwo=styled('div')({
     align:'left'
})



const DateModal = () =>{
     const [openDate,setOpenDate]=useState(false);
     const dispatch = useDispatch();
     const [heure,setHeure]=useState('00:00');

     const handleCloseDate= () => setOpenDate(false);
     const handleOpenDate = () => setOpenDate(true);

     
     /*fonction pour le formatage de date*/
     const formatDate=(value)=>{
          const newdate= moment(value).format('MM/DD/YYYY');
          return newdate;
     }
     const [value, setValue] = useState(formatDate(new Date()));
     const handleDate = () => {
          dispatch(formatAffichageDeadline(value +" " +heure));
          setOpenDate(false)
     }
     return(
          <Fragment>
               <StyledButton
                         variant='contained'
                         onClick={handleOpenDate}
                    >
                         <Styleddivone>
                              <StyleddivTwo>
                                   <AccessTimeIcon/>
                              </StyleddivTwo>
                              Dates
                         </Styleddivone>
                         
               </StyledButton>
               <Modal
                    open={openDate}
                    onClose={handleCloseDate}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
               >
                    <Box sx={Style}>
                         <Grid container mt={2}>
                              <Grid item xs={11}>
                                   <Typography color='#4834d4' bolder variant="h5" id="modal-modal-title">
                                        Date
                                   </Typography>
                                   <Divider/>
                              </Grid>                    
                              <Grid item xs={1}>
                                   <CloseIcon  style={{ 
                                        marginLeft:'20%',
                                        cursor:'pointer',
                                        background:'red',
                                        padding:'5px',
                                        color:'white',
                                        borderRadius:'50%'
                                   }} sx={{fontSize:'large'}} onClick={handleCloseDate}/>     
                              </Grid>
                         </Grid>
                              <form>
                                   <Grid container>
                                        <Grid item xs={12} lg={12} md={12}>
                                             <LocalizationProvider dateAdapter={AdapterDateFns}>
                                             <StaticDatePicker
                                                  orientation="landscape"
                                                  openTo="day"
                                                  value={value}
                                                  onChange={(newValue) => {setValue(formatDate(newValue));}}
                                                  renderInput={(params) => <TextField {...params} />}
                                             />
                                             </LocalizationProvider>
                                        </Grid>
                                   </Grid>
                                   <Grid container spacing={2}>
                                        {
                                             /*
                                             <Grid item xs={6} lg={6} md={6}>
                                             <Typography color='#4834d4' style={{fontSize:12,fontWeight:'bold'}}>
                                                  Date debut
                                             </Typography>
                                             <TextField
                                                  size='small'
                                                  id="date"
                                                  value={moment(new Date()).format('MM/DD/YYYY')}
                                                  fullWidth
                                                  InputLabelProps={{
                                                       shrink: true
                                                  }}
                                             /> 
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                             <Typography color='#4834d4' style={{fontSize:12,fontWeight:'bold'}}>
                                                  Date limite
                                             </Typography>
                                             <TextField
                                                  size='small'
                                                  id="date"
                                                  value={deadline}
                                                  fullWidth
                                                  InputLabelProps={{
                                                       shrink: true
                                                  }}
                                             />
                                        </Grid>
                                             */
                                        }
                                        
                                        <Grid item xs={6} md={6} lg={6}>
                                             <Typography color='#4834d4' style={{fontSize:12,fontWeight:'bold'}}>
                                                  Heures
                                             </Typography>
                                             <TextField
                                                  size='small'
                                                  id="time"
                                                  label=""
                                                  type="time"
                                                  fullWidth
                                                  defaultValue={heure}
                                                  onChange={(event) => setHeure(event.target.value)}
                                                  InputLabelProps={{
                                                       shrink: true
                                                                 }}
                                                  inputProps={{
                                                       step: 300 // 5 min
                                                       }}
                                             />
                                        </Grid>
                                        {
                                             /*
                                             <Grid item xs={6} md={6} lg={6}>
                                             <FormControl fullWidth>
                                                  <Typography color='#4834d4' style={{fontSize:12,fontWeight:'bold'}}>
                                                       Definir un rappel
                                                  </Typography>
                                                  <Select
                                                       value={rappel}
                                                       label=""
                                                       size="small"
                                                       onChange={handleChange}
                                                       >
                                                       <MenuItem value='1d'>1 jour avant</MenuItem>
                                                       <MenuItem value='2d'>2 jours avant</MenuItem>
                                                       <MenuItem value='3d'>3 jours avant</MenuItem>
                                                       <MenuItem value='1w'>1 semaine avant</MenuItem>
                                                       
                                                  </Select>
                                             </FormControl>
                                        </Grid>
                                             */
                                        }
                                        

                                        <Grid item xs={12}>
                                             <Button variant='contained' onClick={() => handleDate()}>
                                                  Enregistrer
                                             </Button>
                                         </Grid>
                                   </Grid>
                              </form>
                    </Box>
               </Modal>                         
          </Fragment>
     )
}

export default DateModal