/*import depedences*/
import axios from "axios"
import React,{Fragment,useEffect,useState} from "react"
import {Card,Typography} from "@mui/material"
import { CardContent,Grid,Button,Modal,Box } from "@mui/material"
import {makeStyles,styled} from "@mui/styles"
import { Draggable } from "react-beautiful-dnd"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useDispatch,useSelector } from "react-redux"
import ClearIcon from '@mui/icons-material/Clear';
import CardMenu from "../kanban/cardMenu";
import { initialState } from "../../../toolkit/user"
import {saveChangeModalKanban, formatAffichageDeadline, getLabels, reinitialiseChecklist, setChecklist, setIdLabel } from "../../../toolkit/projet"
import {Color} from "../../palette/color"

     const StyledSpan = styled('button')({
          fontSize:'6px',
          width:'30px',
          minWidth:'30px',
          maxWidth:'30px',
          borderRadius:'3px 3px',
          marginLeft:'1%',
          height:'5px',
          border:'none'
          
     })

     const StyledLabelList = styled('div')({
          position:'absolute',
          top:-8,
          width:'100%',
          textAlign:'center'
     })

     const UpdatecardButton = styled(Button)({
          '&:hover': {
               background: Color.primary,
          },
          width: '100%',
          marginBottom: 12,
          border: 0,
          borderRadius: 3,
          color: 'white',
          height: 30,
          textTransform: 'uppercase',
     });
   export  const ClearcardIcon = styled(ClearIcon)({
          color: '#fff',
          cursor: 'pointer',
          background: 'red',
          marginTop: '0px',
          padding: '2.8px',
          borderRadius: '5px',
          '&:hover': {
               opacity: 0.8
          }
     })

     const MystyledBox  = styled(Box)({
          width:'40%',
          overflowY:'scroll',
          background:'#fff',
          borderRadius:5,
          border:'none',
          padding:'2%'
 
     })

     const MyDescr = styled('p')({
          color: '#172b4d',
          fontFamily: 'sans-serif',    
          fontSize: '12px',
          marginBottom:'4%',
          maxWidth:'100%',
          marginTop:'-5px',
          fontWeight:700
     })
     const useStyle=makeStyles({
          card:{
               marginBottom:8,
               borderRadius:3,
               cursor:'pointer',
               height:'auto',

          },
          input: {
               borderRadius: 3,
               border: 'none',
               width: '97%',
               height: 60,
               marginBottom: 12,
               resize: 'none',
          }
     })


     const styles = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 250,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          borderRadius:'10px 10px',
          boxShadow: 24,
          p: 2,
     };

const Trellocard = ({save, card,id,index, isScroll}) =>{
     const style=useStyle()
     const BG_COLOR = ['#7BC86C','#F5EA92','#FFAF3F','#EF7564',"#CD8DE5","#5BA4CF"]
     const dispatch = useDispatch()
     const [open,setOpen]=useState(false)
     const [openDeleteModal,setOpenDeleteModal]=useState(false)
     const isOnFire = parseInt(card.etat) === 3 ? true : false;
     const id_projet = useSelector(state => state.projet.id_projet);

     const [edit,setEdit]=useState(false)

     const handleEdit = (e)=>{
          setEdit(true)
     }
     const handleCloseEdit = () =>{
          setEdit(false)
     }

     const handleOpenDeleteModal = () =>{
          setOpenDeleteModal(true)
     }

     const handleCloseDeleteModal = () =>{
          setOpenDeleteModal(false)
     }


     const handleOpen = async(e) =>{
          try {
               dispatch(setIdLabel(id));
               dispatch(formatAffichageDeadline(card.deadline));
               const resChecklist = await axios.get(initialState.url +"checklist/label/"+id);
               const resChecklistMembre = await axios.get(initialState.url +"label/"+id+"/membre");
               dispatch(setChecklist({checklist:resChecklist.data, membreChecklist:resChecklistMembre.data, description: card.description,  nameLabel: card.name}));
          } catch (error) {
          }
          setOpen(true)
     }
     const handleClose = () =>{
          dispatch(reinitialiseChecklist());
          setOpen(false)
     }

     const saveChange = async (confirmation = "Validé") => {
          const result = await dispatch(saveChangeModalKanban(confirmation));
          setDateAffiche(affichageDeadline(result.payload));
          dispatch(getLabels(id_projet));
          setOpen(false);
     }
     
     const affichageDeadline = (payload) => {
          const moisFr = ['Jan', 'Fév', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];
          const deadline = payload ? new Date(payload) : null;
          const affiche = deadline && deadline !== 'Invalid Date' ?  deadline.getDate() + " " + moisFr[deadline.getMonth()] + " " + (deadline.getHours().toString().length >1 ? deadline.getHours(): "0"+ deadline.getHours()) +":"+(deadline.getMinutes().toString().length >1 ? deadline.getMinutes(): "0"+ deadline.getMinutes()) : "";
          return affiche;
     }
     const [dateAffiche, setDateAffiche] = useState(affichageDeadline(card.deadline));
     const checkStyle = card.checked === card.total_checklist ? { backgroundColor:'#009432', color:'white'} : { backgroundColor:Color.primary, color:'white'} 

     return(
          <Fragment>
               <Draggable draggableId={String(id)} index={index}>
                    {provided => (
                    <div 
                    ref={provided.innerRef} 
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    >
                         {edit===true ?
                         <Box>
                              <textarea
                                   autoFocus
                                   className={style.input}
                                   type="text"
                                   placeholder="Tapez ici sa description"
                                   defaultValue={card.name}
                              />
                              <Grid container>
                                   <Grid item xs={8}>
                                        <UpdatecardButton style={{backgroundColor:Color.primary,color:'white'
                                             }} onClick={handleEdit}>
                                             Modifier
                                        </UpdatecardButton>
                                   </Grid>
                                   <Grid ml={3} item xs={2} onClick={handleCloseEdit}>
                                        <ClearcardIcon />
                                   </Grid>
                              </Grid>
                         </Box>
                         :
                         <Card className={style.card} sx={{ width: isScroll ? '100%': "100%" , paddingBottom: '5px', background: isOnFire ? '#ed220e' : 'white',position:'relative'}}>
                              <StyledLabelList>
                                   <Grid container style={{marginTop:0}}>
                                        {BG_COLOR.map((nb)=>(
                                             <Grid item xs={2} lg={2} md={2}>
                                                  <StyledSpan style={{backgroundColor:nb}}></StyledSpan>
                                             </Grid>
                                        ))}
                                   </Grid>
                              </StyledLabelList>
                              <Modal
                                   open={open}
                                   onClose={handleClose}
                                   sx={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center',
                                        overflowY:'scroll',
                                        paddingTop:'0px'
                                   }}
                              >
                                   <MystyledBox>
                                        <CardMenu save={save} handleClose={handleClose} saveChange={saveChange}/>
                                   </MystyledBox>
                              </Modal>
                              <Modal
                                   open={openDeleteModal}
                                   onClose={handleCloseDeleteModal}
                                   aria-labelledby="modal-modal-title"
                                   aria-describedby="modal-modal-description"
                                   >
                                   <Box sx={styles}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Voulez-vous vraiment supprimer <Typography sx={{fontWeight:'700',color:'#00f'}}>{card.name} ?</Typography>
                                        </Typography>
                                        <Grid container sx={{marginTop:'4%'}}>
                                             <Grid item xs={1}>

                                             </Grid>
                                             <Grid item sx={{marginRight:'8%'}} xs={4}>
                                                  <Button onClick={handleCloseDeleteModal} style={{backgroundColor:'green',color:'white'}}>NON</Button>
                                             </Grid>
                                             <Grid item={2}>
                                                  
                                             </Grid>
                                             <Grid item xs={4}>
                                                  <Button  style={{backgroundColor:'red',color:'white'}}>OUI</Button>
                                             </Grid>
                                             <Grid item xs={1}></Grid>
                                        </Grid>
                                   </Box>
                              </Modal>
                              <Grid container mt={1} >
                                   <Grid item xs={10} lg={10}>
                                        <CardContent style={{height:'auto'}} >
                                             <Box onClick={handleOpen}>
                                                  <Button  variant="text" style={{ textTransform:'capitalize'}}>
                                                       <MyDescr>
                                                            {card.name}
                                                       </MyDescr>
                                                  </Button>
                                             </Box>
                                        </CardContent>
                                   </Grid>
                                   <Grid item xs={2} lg={2} id='notClick'>
                                        <DeleteIcon onClick={handleOpenDeleteModal}/>
                                        <Grid container  sx={{marginTop:'4%',width:'80%',cursor:'pointer'}}  >
                                             <Grid item xs={12} lg={12}>
                                                  <Box>
                                                       <ModeEditIcon  onClick={handleEdit} color="primary"/>
                                                  </Box>
                                             </Grid>
                                        </Grid>
                                   </Grid>
                               </Grid>
                              <Grid container spacing={3} pl={2} sx={{ marginTop: '-30px'}}>
                                   <Grid item xs={4} lg={3}>
                                        <Button size="small" style={checkStyle} startIcon={<CheckBoxIcon/>}>{card.checked ? card.checked : 0}/{card.total_checklist ? card.total_checklist : 0}</Button>
                                   </Grid>
                                   <Grid  item xs={9} lg={9} mt={1} justifyContent='end' display='flex' sx={{alignItems:'center'}}>
                                        <AccessTimeTwoToneIcon color="action"/>
                                        <Typography sx={{ paddingRight: '5px', color: isOnFire ? 'black' : 'grey'}} paddingTop={0.5} variant='caption'>{ dateAffiche ? dateAffiche : "" } </Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                         }
                    </div>
                    
                    )}
               </Draggable>
          </Fragment>
     )
}
export default Trellocard;