import React from "react"
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { makeStyles } from '@mui/styles'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    style={{
        marginTop:'10px',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    maxWidth: 300,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const useCreateMenuStyles=makeStyles({
  root:{
    backgroundColor:"#dfe6e9",
    
  },
  Container:{
    padding:'0px 20px 20px 20px',
    
  },
  imagefond:{
    width:'100%',
    minHeight:'150px',
    maxHeight:'150px'
  },
  choice:{
    cursor:'pointer'
  },
  err:{
    color:"red",
    textAlign:'center'
  }
})


export default StyledMenu;