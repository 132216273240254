import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./user";
import { projetSlice } from "./projet";
import ModalSlice from "./Modal";

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        projet: projetSlice.reducer,
        modal:ModalSlice.reducer
    },
})

export default store
