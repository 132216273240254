import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Auth from '../pages/auth/signin.js'
import HomeContainer from '../pages/HomeContainer/HomeContainer';
import Kanban from '../pages/Project'
import Workspace from '../pages/workspace/index'
import SignUp from '../pages/auth/signup.js'
import ProtectedRoute  from './ProtectedRoute.js';
import Accueil from '../pages/Accueil/index.js';
import User from '../pages/user/index.js';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <div>
          <Switch>
          <Route component={Accueil} path="/" exact={true} />
            <Route component={Auth} path="/login" exact={true} />
            <Route component={SignUp} path="/signup" exact={true} />
            <ProtectedRoute component={HomeContainer} path="/home" exact={true}/>
            <ProtectedRoute component={Kanban} path="/kanban/:id" exact={true}/>
            <ProtectedRoute component={Workspace} path="/workspace" exact={true} />
            <ProtectedRoute component={User} path="/utilisateur" exact={true} />
          </Switch>
      </div>
    </BrowserRouter>
  );
};

export default AppRouter;