import * as React from 'react';
import { useState } from 'react';
import {Box, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContainerCreateMenu from '../../Menu/ContainerCreateMenu'

const useStyles = makeStyles((theme)=>({
  root: {
   height:'90px',
   width:'210px',
   fontFamily:'Arial',
   padding:'5px 5px 5px 5px',
   borderRadius:'5px',
   margin:'10px 0px 10px 0px',
   cursor:'pointer',
   fontSize:'16px',
   transition:'all 0.8s',
   '&:hover':{
        boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    "& a":{
      textDecoration:'none'
    }
  },
  model:{
    width:'55px',
    height:'22px',
    background:'#FFFFFFB0',
    borderRadius:'5px',
    textAlign:'center',
    fontWeight:'bold',
    '& .p-model':{
        fontSize:'11px',
        color:'#172B4D'
    }
  },
  title:{
    display:'flex',
    flexWrap:'wrap',
    textDecoration:'none',
    color:'white',
    overflow:'auto',
    marginTop:'15px',
    '& .p-title':{
          fontWeight:'bold',
          
      }
  }
}));

export default function CardMenu({ menu }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] =  useState(false)
  const [isUpdate, setIsUpdate] =  useState(false)
  const handleClose = () =>{
    setIsOpen(false)
    setIsUpdate(false)
  }

  const handleOpen = () =>{
    setIsOpen(true)
    setIsUpdate(true)
  }

  const path = "/kanban/" + menu.id
  return (
    <Box className={classes.root}  style={{ 
        background: `url(${menu.backgroundImage}), #0000004D`,
        backgroundSize:'cover',
        display:'flex',
        flexDirection:'row'
    }} >
        <Box flex={3} >
          <Link to={{pathname: path}} >
              <Box className={classes.model}>
                  <Typography variant='p' className='p-model'>
                    Modèle
                  </Typography>
              </Box>
              <Box className={classes.title}>
              <Typography variant='p' className='p-title' noWrap>
                {menu.name.length > 15 ? menu.name.slice(0,15) + "..." : menu.name}
              </Typography>
              </Box>    
          </Link>
        </Box>
        <Box sx={{ flex:1}}>
          <IconButton 
            aria-label="Modifier"  
            title='Modifier'
            id='update-button' 
            sx={{ 
              backgroundColor: '#FFFFFFB0',
              '&:hover':{
                backgroundColor: '#172B4D',
                color:'#FFFFFFB0'
              } 
            }}
            onClick={() => handleOpen()}
          >
            <EditIcon id='update-icon'   fontSize='1px' />
          </IconButton>
        </Box>
        <ContainerCreateMenu project={menu} isOpen={isOpen} isUpdate={isUpdate} handleClose={handleClose} handleOpen={handleOpen}/>
    </Box>
  );
}