import {styled} from "@mui/styles"

const StyledButton = styled('button')({
     backgroundColor:"#95a5a6",
     width:'100%',
     cursor:'pointer',
     color:"#172b4d",
     borderRadius:'5px 5px',
     border:'none',
     alignItems:'center',
     fontSize:'12px',
     padding:'5px 5px',
          '&:hover':{
               backgroundColor:"#7f8c8d",
               boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', 
               color:'white',
          },
          '&:active':{
               boxShadow: 'none', 
          }
})

export default StyledButton;
