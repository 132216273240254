import React,{ Fragment,useState } from "react"
import {Box,Grid,Stack,Typography,Select,ListItemText,FormControl,MenuItem} from "@mui/material"
import OutlinedInput from '@mui/material/OutlinedInput';
import {Modal,Button} from "@mui/material"
import InputLabel from '@mui/material/InputLabel';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from "react-redux";
import {styled} from "@mui/styles"


import { setMembreChecklist } from "../../toolkit/projet";
import Style from "./Style"
import StyledButton from "./StyledButton";


export const Styleddivone=styled('div')({
     alignItems:'center',
     display:'flex'
})

export const StyleddivTwo=styled('div')({
     align:'left'
})
     
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
PaperProps: {
style: {
     maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
     width: 250,
},
},
};

const MembreModal = () =>{
     const dispatch = useDispatch();
     const [openMembre, setOpenMembre] = useState(false);
     const [membres, setMembres] = useState([]);
     const listMembres = useSelector(state => state.projet.equipeProjet);
     const handleChangeMembres = (event) => {
          setMembres(event.target.value)
     };

     const handleCloseMembre= () => setOpenMembre(false);
     const anciensMembres = useSelector(state => state.projet.membreChecklist);
     const handleAddMembre = () =>{
          if(membres.length){
               dispatch(setMembreChecklist({newMembre: membres, anciens:anciensMembres}));
          }
          setOpenMembre(false);
     }
     const handleOpenMembre=()=>{
          setOpenMembre(true)
     }
     return(
          <Fragment>
               <StyledButton 
                         onClick={handleOpenMembre}
                         variant='contained'   
                    >
                    <Styleddivone>
                         <StyleddivTwo>
                              <AccountCircleIcon/>
                         </StyleddivTwo>
                         Membres
                    </Styleddivone>
               </StyledButton>
               <Modal
                    open={openMembre}
                    onClose={handleCloseMembre}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
               >
                    <Box sx={Style}>
                         <Grid container>
                              <Grid container item xs={11} spacing={2}>
                                   <Typography color='#4834d4' sx={{fontSize:'17px'}} variant="h8" component="h2">
                                        <PersonIcon/>
                                        Ajouter des  membres à ce tache
                                   </Typography>
                              </Grid>
                              <Grid item xs={1}>
                                   <CloseIcon style={{marginLeft:'20%',cursor:'pointer'}} sx={{fontSize:'medium',color:'red'}} onClick={handleCloseMembre}/>     
                              </Grid>
                         </Grid>
                         <Grid>
                              <FormControl sx={{ m: 1, width: 300,mt:3 }}>
                                   <div>
                                        <InputLabel id="demo-multiple-checkbox-label">
                                             <Typography style={{fontSize:12}}>
                                                  Choisissez des membres
                                             </Typography>
                                        </InputLabel>
                                        <Select
                                             style={{width:'100%'}}
                                             labelId="demo-multiple-checkbox-label"
                                             id="demo-multiple-checkbox"
                                             multiple
                                             value={membres}
                                             onChange={handleChangeMembres}
                                             input={<OutlinedInput label="Tag" />}
                                             MenuProps={MenuProps}
                                        >
                                             {listMembres.map((m, index) => (
                                                  <MenuItem key={index} value={m}>
                                                       <ListItemText primary={m.last_name} />
                                                  </MenuItem>
                                             ))}
                                                  </Select>
                                   </div>
                              </FormControl>
                         </Grid>
                         <Stack sx={{marginTop:'5%'}}>
                              <Button 
                                   variant='contained'
                                   ize="large"
                                   onClick={handleAddMembre}
                              >
                                   Ajouter
                              </Button>
                         </Stack>
                    </Box>
               </Modal>
          </Fragment>
     )
}

export default MembreModal;