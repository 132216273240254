import * as React from 'react';
import PropTypes from 'prop-types';
import SelectUnstyled from '@mui/base/SelectUnstyled';
import { StyledButton } from './styledButton';
import {StyledListbox} from "./styledListbox"
import {StyledOption} from "./styledOption"
import {StyledPopper} from "./styledPopper"


function CustomSelect(props) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};

function renderValue(option) {
  if (option == null) {
    return <span>Trier par</span>;
  }

  return (
    <span>
      {option.label} ({option.value})
    </span>
  );
}

export default function ButtonTrier() {
  return (
    <CustomSelect renderValue={renderValue}>
      <StyledOption value={10}>Activité plus récente</StyledOption>
      <StyledOption value={20}>Valeur moins récente</StyledOption>
      <StyledOption value={20}>Par ordre alphabetique de A à Z</StyledOption>
      <StyledOption value={20}>Par ordre alphabetique de Z à A</StyledOption>
    </CustomSelect>
  );
}