import React, { useState,useEffect } from "react";
import axios from 'axios';
import { Pets } from "@mui/icons-material";
import {AppBar,Avatar,Badge,Box,InputBase, Menu,MenuItem,styled,Typography,Stack,Grid} from "@mui/material";
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { useHistory } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import {useSelector,useDispatch} from "react-redux"
import CloseIcon from '@mui/icons-material/Close';
import { getNotifications } from "../../toolkit/projet";
import DehazeIcon from '@mui/icons-material/Dehaze';

import { StyledLink } from "./SignupNav";
import CreateMenu from "../../components/Menu/CreateMenu";
import { Color } from "../palette/color";
import Notification from './Notification';
import Createquipemenu from "../../components/Menu/CreateEquipe"
import CarteModal from '../Modal/CarteModal';
import {formatAffichageDeadline, setChecklist, setIdLabel, changeStatuspersonnel } from "../../toolkit/projet"
import { initialState } from "../../toolkit/user";
import {Link} from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import InputIcon from '@mui/icons-material/Input';
import Button from '@mui/material/Button';

const StyledNom = styled('p')({
  textAlign:'center',
  fontSize:'12px',
  color:'#222f3e',
  lineHeight:'10px',
  fontWeight : 700,
  marginLeft:'2%'
})


export const StyledTitle =styled('p')({
  textAlign:'center',
  fontSize:'12px',
  color:'#5e6c84',
  lineHeight:'10px'

})

const Style = {
  fontSize:'12px',
  position: 'absolute',
  minWidth:300,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 2,
};

const StyledToolbar = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  background:Color.primary,
  height:'15px',
  alignItems:'center',
  padding:'1% 1%',
});

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  width: "30%",
  height:'30px',
  fontSize:'12px'
}));

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));


const StyledStack=styled(Stack)({
  alignItems:'center',

})

const StyledDivOne = styled('div')({
  alignItems:'center',
  display:'flex'
})



const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const userId = localStorage.getItem('user-this_id');
  const logout = () => {
    localStorage.removeItem('user-token');// clear your user's token from localstorage
    localStorage.removeItem('user-this_id'); 
    delete axios.defaults.headers.common['Authorization'];
    history.push("/");
  }
  
  const {notifications}  = useSelector(state => state.projet);
  const [openModal,setOpenModal]=useState(false);
  const [colonne, setColonne] = useState(null);
  const nbrNotification = useSelector(state => state.projet.nbrNotification);
  const selectNotification = async (label) => {
    try {
      dispatch(setIdLabel(label.id));
      dispatch(formatAffichageDeadline(label.deadline));
      await axios.patch(initialState.url +"label/notificationChangeEtat/"+label.id+"/"+userId+"/true");
      const resChecklist = await axios.get(initialState.url +"checklist/label/"+label.id);
      const resChecklistMembre = await axios.get(initialState.url +"label/"+label.id+"/membre");
      dispatch(setChecklist({checklist:resChecklist.data, membreChecklist:resChecklistMembre.data, description: label.description,  nameLabel: label.name}));
      setColonne(label.colonne ? label.colonne : label.colonneIdId);
      dispatch(getNotifications(localStorage.getItem('user-this_id')));
    } catch (error) {
    }
    setOpenModal(true);
  }
  
  useEffect(() => {
    dispatch(getNotifications(localStorage.getItem('user-this_id')));
  }, [dispatch])

  const [open, setOpen] = useState(false);
  return (
    <AppBar position="sticky">
      <StyledToolbar>
        <Typography to='/home' variant="h6" sx={{ display: { xs: "none", sm: "block" } }}>
          <StyledStack direction="vertical" gap={1}>
            <Link to="/home">
              <Avatar variant='square' style={{width:'100px',height:'40px'}} src='/images/logo12.png'>
              </Avatar>
            </Link>
            <Link style={{textDecoration:'none'}} to="/home">
              <StyledLink>
                TICKETING
              </StyledLink>
            </Link>
            <CreateMenu/>
            <Createquipemenu/>
            <Button
              id="demo-customized-button"
              aria-haspopup="true"
              variant="contained"
              disableElevation
              onClick={() => dispatch(changeStatuspersonnel())}
              style={{fontSize:'12px',
              textAlign:'center',
              fontWeight:700,
              color:'#fff',backgroundColor:Color.primary,
              textTransform: "capitalize"}}
            >
              Personnel
            </Button>
          </StyledStack>
        </Typography>
        <DehazeIcon sx={{ display: { xs: "block", sm: "none",cursor:'pointer' } }} />
        <Icons>
          <Search>
            <InputBase placeholder="Parcourir"/>
          </Search>
          <IconButton
              size="small"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleClick}
            >
              <Badge badgeContent={nbrNotification} color="error">
                <NotificationsIcon />
              </Badge>
          </IconButton>
          <Notification selectNotification={selectNotification} anchorEl={anchorEl} handleClose={handleClose} data={notifications}/>
          <InputIcon onClick={(e) => setOpen(true)}/>
          
        </Icons>
        {/* 
        <UserBox onClick={(e) => setOpen(true)}>
          <Avatar
            sx={{ width: 30, height: 30 }}
            src="https://avatars.githubusercontent.com/u/80751503?s=400&u=6a0d04a90a1089e5ad180560b65371d56c0a20a8&v=4"
          />
          <Typography variant="span">Hasina</Typography>
        </UserBox>
        */}
      </StyledToolbar>
      <Paper sx={{ width: 320 }}>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{
          vertical: 40,
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      
      >
        {/*
        <MenuItem>
          <Grid container mt={2}>
            <Grid item xs={11}>
                  <StyledTitle>Compte</StyledTitle>
            </Grid>                    
            <Grid item xs={1}>
              <CloseIcon 
              onClick={()=>setOpen(false)} 
              style={{ 
                  marginLeft:'10%',
                  cursor:'pointer',
                  padding:'5px',
                  color:'#2d3436',
                  borderRadius:'50%',
                  fontSize:'12px'
                  }}/>
            </Grid>
          </Grid>
        </MenuItem>
        <Divider/>
        
        <MenuItem>
          <ListItemText>
            <StyledDivOne>
                  <Avatar
                  sx={{ width: 30, height: 30 }}
                  src="https://avatars.githubusercontent.com/u/80751503?s=400&u=6a0d04a90a1089e5ad180560b65371d56c0a20a8&v=4"
                />
                <StyledNom> RAMAMIHARIVELO Marihasina</StyledNom>
            </StyledDivOne>
          </ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem>
          <StyledTitle>Profil</StyledTitle>
        </MenuItem>
        <MenuItem>
          <StyledTitle>Paramètres</StyledTitle>
        </MenuItem>
        */
        }
        <MenuItem>
          <StyledTitle onClick={()=>logout()}>Se déconnecter</StyledTitle>
        </MenuItem>
      </Menu>
    </Paper>
      <CarteModal
        colonne={colonne}
        open={openModal}
        setOpen={setOpenModal}
      />
    </AppBar>
    )
  }

export default Navbar;