import React from "react"
import {Color} from "../palette/color"
import {Grid,Stack,Avatar} from "@mui/material"
import {styled} from "@mui/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone,faMailBulk} from "@fortawesome/free-solid-svg-icons";
import { StyledSearch,StyledInput } from "../Accueil/Section1";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const StyledDiv  = styled('div')({
  background:Color.primary,
  padding:'20px 10px',

})

const StyledInscrire=styled('button')({
  alignItems: 'center',
  borderRadius: '0.3rem',
  cursor: 'pointer',
  display: 'inline-flex',
  backgroundColor: '#000',
  textDecoration: 'none',
  fontSize: '12px',
  padding: '0.4rem 2rem',
  color: 'rgb(255, 255, 255) !important',
  height:'2rem',
  display:'align-content'

})

const StyledTitle=styled('p')({
  color:'#fff',
  fontSize:'14px',
  fontWeight:700,
  marginBottom:'5%'
  
})
const StyledParagraph=styled('p')({
  color:'#fff',
  fontSize:'10px',
  fontWeight:500,
  marginBottom:'10%',
  maxWidth:'70%',
  lineHeight: 2,

})

const Footer = () => {
    return(
     <>
      <StyledDiv>
        <Grid container>
          <Grid item xs={12} lg={3}  xl={3} md={6} sm={6}>
              <StyledTitle>Contactez-nous</StyledTitle>
              <StyledParagraph>
                <Stack direction="vertical" gap={1}>
                  <FontAwesomeIcon icon={faPhone}/>
                  (+261 20) 22 236 47 / (+261 20) 22 213 75
                </Stack>
                <Stack direction="vertical" gap={1}>
                <FontAwesomeIcon icon={faMailBulk}/>
                contact@institutfrancais-madagascar.com
                </Stack>
                Copyright {new Date().getFullYear()} @ iteam-$
              </StyledParagraph>
          </Grid>
          <Grid item xs={12} lg={3}  xl={3} md={6} sm={6}>
            <StyledTitle>Adresse</StyledTitle>
            <StyledParagraph>
              Institut Français de Madagascar Ambassade de France à Madagascar 14, avenue de l’Indépendance BP 488 101 Antananarivo MADAGASCAR
            </StyledParagraph>
            <Avatar variant='square' style={{width:'100px',height:'50px'}} src='/images/logo12.png'>
            </Avatar>
          </Grid>
          <Grid item xs={12} lg={3}  xl={3} md={6} sm={6}>
            <StyledTitle>Restez-informé!</StyledTitle>
            <Grid container gap={2}>
              <Grid item xs={12} lg={5}  xl={5} md={12} sm={12}>
                <StyledSearch>
                      <StyledInput placeholder="Email"/>
                </StyledSearch>
              </Grid>
              <Grid item xs={12} lg={5}  xl={5} md={12} sm={12}>
                <StyledInscrire>Inscrire</StyledInscrire>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}  xl={3} md={6} sm={6}>
            <StyledTitle>Suivez-nous sur</StyledTitle>
            <Stack direction='vertical' gap={1}>
              <FacebookIcon  style={{backgroundColor:"#fff",color:"#000"}}/>
              <LinkedInIcon style={{backgroundColor:"#fff",color:"#000"}}/>
            </Stack>
          </Grid>
        </Grid>
      </StyledDiv>
     </>
    )
  }

export default Footer