
const Style = {
     fontSize:'12px',
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     bgcolor: 'background.paper',
     border: '2px solid #fff',
     boxShadow: 24,
     p: 2,
};

export default Style;