import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


export default function MediaControlCard() {

  return (
     <>
     <div style={{width:"80%",marginLeft:"20%",backgroundColor:"#dcdde1"}}>
     <div style={{marginLeft:"20%"}}>
     <Card sx={{ display: 'flex',bgcolor:"#dcdde1" }}>
     <CardMedia
        component="img"
        sx={{ width: 120}}
        image="https://pbs.twimg.com/profile_images/513102050855378944/8zLRw1-g.jpeg"
        alt="Alliance fraçaise"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            Alliance Française workspace
          </Typography>
          <Button
          id="demo-customized-button"
          variant="contained"
          disableElevation
          sx={{ bgcolor: "#7f8fa6",textTransform: "capitalize"}}
          >
          Modifier le details de l'espace de travail
          </Button>
        </CardContent>
      </Box>
    </Card>
    </div>
    <Box sx={{mt:"1.2%",ml:"20%", flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button
          id="demo-customized-button"
          variant="contained"
          disableElevation
          sx={{ bgcolor: "#7f8fa6",textTransform: "capitalize"}}
          >
          Tableaux
          </Button>
          <Button
          id="demo-customized-button"
          variant="contained"
          disableElevation
          sx={{ml:"1.2%", bgcolor: "#7f8fa6",textTransform: "capitalize"}}
          >
          Tableur d'espace de travail
          </Button> 
          <Button
          id="demo-customized-button"
          variant="contained"
          disableElevation
          sx={{ml:"1.2%", bgcolor: "#7f8fa6",textTransform: "capitalize"}}
          >
          Membres
          </Button> 
          <Button
          id="demo-customized-button"
          variant="contained"
          disableElevation
          sx={{ml:"1.2%", bgcolor: "#7f8fa6",textTransform: "capitalize"}}
          >
          Paramètres
          </Button>
          <Button
          id="demo-customized-button"
          variant="contained"
          disableElevation
          sx={{ml:"1.2%", bgcolor: "#7f8fa6",textTransform: "capitalize"}}
          >
          Promouvoir l'offre de l'espace de travail
          </Button>
     </Box>
    </div>
    </>
  );
}